/**
 * @Description Descarga el archivo creando una etiqueta a
 * @param {String} url
 * @param {String} fileName
 */
export const downloadFile = async (url, fileName = 'file') => {
  const response = await fetch(url, { method: 'GET' })
  if (response.ok) {
    const a = document.createElement('a')
    const url = window.URL.createObjectURL(await response.blob())
    //Link styles
    a.style.display = 'none'
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    //Click link
    a.click()
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }
}
