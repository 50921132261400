import AdminAPI from '@src/services/API'
import { useQuery } from '@tanstack/react-query'
import {
    SpinnerLoader,
    JpPDFVertical,
    JpButton,
    parseName
} from '@Intelli/utilities'
import { useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import ContractDataV2 from './steps/ContractDataV2'
import Wizard from '@src/components/general/stepper'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Summary from './steps/Summary'
import { addBreadcrumbs } from '@src/redux/breadcrumbs'
import { useDispatch } from 'react-redux'

const getDefaultValues = contractorData => {
    const additionalFields = [
        'HOURS_PER_DAY',
        'START_DATE',
        'END_DATE',
        'POSITION',
        'SALARY',
        'RESPONSIBILITIES'
    ]

    const baseDefaultValues = {
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().add(1, 'day').format('YYYY-MM-DD'),
        responsibilities: '',
        payment_rule_id: null,
        salary: 0,
        teams_id: []
    }

    if (!!contractorData.teams) {
        baseDefaultValues.teams_id = contractorData.teams.map(team => ({
            value: team.id,
            label: team.name
        }))
    }

    if (!!contractorData.contractor_metadata.CONTRACT_TEMPLATE_ID) {
        baseDefaultValues.contract_template_id =
            contractorData.contractor_metadata.CONTRACT_TEMPLATE_ID
    }

    if (contractorData.contractor_metadata) {
        const additionalDefaultValues = {}
        additionalFields.forEach(field => {
            if (!!contractorData.contractor_metadata[field]) {
                additionalDefaultValues[field.toLowerCase()] =
                    contractorData.contractor_metadata[field]
            }
        })
        return { ...baseDefaultValues, ...additionalDefaultValues }
    }

    return baseDefaultValues
}

const RenewContract = () => {
    const API = new AdminAPI()
    const { id } = useParams()
    const { t } = useTranslation()
    const [stepper, setStepper] = useState()
    const [proccessData, setProccessData] = useState(null)
    const [activeIndex, setActiveIndex] = useState(0)
    const [defaultValues, setDefaultValues] = useState(null)
    const refStepper = useRef(null)
    const dispatch = useDispatch()

    const { data: contractorData, isSuccess: contractorIsSuccess } = useQuery(
        ['contractorData'],
        () => API.getContractor(id),
        {
            enabled: !!id
        }
    )

    const {
        data: legalRepresentativesData,
        isSuccess: legalRepresentativesIsSuccess
    } = useQuery(
        ['legalRepresentatives'],
        () => API.getLegalRepresentatives(),
        {
            enabled: !!contractorData
        }
    )

    dispatch(
        addBreadcrumbs({
            id,
            value: [
                {
                    name: parseName(
                        contractorData?.user?.first_name,
                        contractorData?.user?.last_name
                    ),
                    last: true
                }
            ]
        })
    )

    useEffect(() => {
        if (!!contractorData && !defaultValues) {
            setDefaultValues(getDefaultValues(contractorData))
        }
    }, [contractorData])

    if (!contractorIsSuccess || !legalRepresentativesIsSuccess)
        return <SpinnerLoader />
    const method = contractorData?.modality
    const type = {
        name: contractorData?.contract_type_name,
        id: contractorData?.contract_type_id
    }

    const steps = [
        {
            id: 'contract',
            subtitle: t('views.newContract.steps.contract.title'),
            containerClass: 'bg-transparent border-0 shadow-none',
            content: (
                <ContractDataV2
                    method={method}
                    type={type}
                    contractorData={contractorData}
                    defaultValues={defaultValues}
                    stepper={stepper}
                    setProccessData={setProccessData}
                    renew
                    legalRepresentativesData={legalRepresentativesData}
                />
            )
        },
        {
            id: 'previrew',
            subtitle: t('views.newContract.steps.preview.title'),
            content: (
                <JpPDFVertical pdf={localStorage.getItem('previewURL') || ''} />
            ),
            containerClass: 'bg-transparent border-0 shadow-none'
        },
        {
            id: 'summary',
            subtitle: t('views.newContract.steps.summary.title'),
            containerClass: 'bg-transparent border-0 shadow-none',
            content: (
                <Summary
                    contractorData={contractorData}
                    data={proccessData}
                    type={type}
                    stepper={stepper}
                />
            )
        }
    ]

    return (
        <>
            <Wizard
                type="modern-horizontal"
                boxType="circle"
                stepTextPosition="bottom"
                ref={refStepper}
                steps={steps}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                instance={el => setStepper(el)}
                headerClassName="d-flex justify-content-center p-0"
                contentWrapperClassName="p-0 mt-2 card"
                className="register-multi-steps-wizard shadow-none h-100"
            />
            {activeIndex === 1 && (
                <div className="d-flex justify-content-between">
                    <JpButton
                        options={{
                            onClick: () => {
                                stepper.previous()
                            },
                            iconPosition: 'left'
                        }}
                        type="back"
                    />
                    <JpButton
                        options={{
                            onClick: () => stepper.next()
                        }}
                        type="next"
                    />
                </div>
            )}
        </>
    )
}

export default RenewContract
