import { Card, Col, Row } from 'reactstrap'
import {
    NoData,
    JpDatePicker,
    JpText,
    dateLocalizer,
    SpinnerLoader,
    formatDatesUtc,
    JpAvatar
} from '@Intelli/utilities'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays } from '@fortawesome/pro-solid-svg-icons'
import { useQuery } from '@tanstack/react-query'
import AdminAPI from '@src/services/API'
import { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import ChartComponent from '../productivity/TimelineChart'
import AppsModal from '../productivity/AppsModal'
import DetailsChartsCards from './DetailsChartsCards'
import findMinMaxDates from '@src/utility/findMinMaxDates'
import useSocket from '@src/hooks/useSocket'

const CONTRACTORS_PER_PAGE = 5

const TimelineDetails = () => {
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [queries, setQueries] = useState('')
    const [start_date, setStartDate] = useState(
        moment().subtract(1, 'week').format('YYYY-MM-DD')
    )
    const [end_date, setEndDate] = useState(moment().format('YYYY-MM-DD'))
    const [querySession, setQuerySession] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [minMaxDates, setMinMaxDate] = useState({})
    const [sortBy, setSortBy] = useState('start_datetime__desc')

    const { id } = useParams()

    const API = new AdminAPI()

    const {
        data: getTimelineDetails,
        isLoading: getTimelineDetailsIsLoading,
        refetch: timelineRefetch
    } = useQuery(
        [
            'getTimelineDetails',
            currentPage,
            CONTRACTORS_PER_PAGE,
            queries,
            shouldUpdate,
            start_date,
            end_date,
            sortBy
        ],
        () =>
            API.getTimelineDetails(
                {
                    limit: CONTRACTORS_PER_PAGE,
                    offset: currentPage * CONTRACTORS_PER_PAGE,
                    query: queries,
                    order: sortBy,
                    start_datetime__between: `${formatDatesUtc.getDateStringtoUTC(
                        start_date
                    )},${formatDatesUtc.getDateStringtoUTC(
                        moment(end_date).add(1, 'days').format('YYYY-MM-DD')
                    )}`
                },
                id
            ),
        {
            onSuccess: ({ data }) => {
                const minMaxDates = findMinMaxDates(data)
                setMinMaxDate(minMaxDates)
            }
        }
    )

    const { data: getAllAppsSession, isLoading: getAllAppsSessionIsLoading } =
        useQuery(
            ['getAllAppsSession', querySession, shouldUpdate],
            () =>
                API.getAllAppsSession(
                    {
                        contractor_id: querySession.contractor_id,
                        session_id: querySession.session_id
                    },
                    {
                        order: 'start_datetime__asc',
                        date: `${formatDatesUtc.getDateStringtoUTC(
                            querySession.start_datetime
                        )}`
                    }
                ),
            { enabled: !!querySession }
        )

    useSocket(
        {
            [`/productivity/contractors/${id}/timeline`]: {
                fn: value => {
                    if (end_date === moment().format('YYYY-MM-DD')) {
                        timelineRefetch()
                    }
                }
            }
        },
        [end_date]
    )

    useEffect(() => {
        const timeout = setTimeout(() => {
            refrestData()
        }, 300)
        return () => {
            clearInterval(timeout)
        }
    }, [queries])

    const refrestData = (options = {}) =>
        fetcher({
            query: queries,
            ...options
        })

    const fetcher = ({ query }) => {
        setQueries(query)
    }

    const handlePagination = ({ selected }) => {
        setCurrentPage(selected)
    }

    return (
        <>
            <DetailsChartsCards start_date={start_date} end_date={end_date} />
            <Card className="px-2">
                <Row className="my-2 g-1">
                    <Col
                        sm="4"
                        md="4"
                        lg="3"
                        className="d-flex justify-content-start table-filters mt-1 mt-md-0"
                    >
                        <JpDatePicker
                            id="contractStartDate"
                            value={start_date}
                            setPicker={date => {
                                setCurrentPage(0)
                                setStartDate(date.format('YYYY-MM-DD'))
                            }}
                            maxDate={end_date}
                            className={'enabled-joobpay'}
                        />

                        <JpDatePicker
                            id="contractEndDate"
                            value={end_date}
                            minDate={moment(start_date).format('YYYY-MM-DD')}
                            maxDate={moment().format('YYYY-MM-DD')}
                            setPicker={date => {
                                setCurrentPage(0)
                                setEndDate(date.format('YYYY-MM-DD'))
                            }}
                            className={'enabled-joobpay ms-1'}
                        />
                    </Col>
                </Row>
                {getTimelineDetailsIsLoading ? (
                    <SpinnerLoader />
                ) : getTimelineDetails?.count > 0 ? (
                    getTimelineDetails?.data?.map((item, index) => (
                        <Row
                            key={item.id}
                            className="py-2 border-top border-bottom"
                        >
                            <Col className="d-flex align-items-center" lg="2">
                                <div className="d-flex align-items-center">
                                    <JpAvatar
                                        icon={
                                            <FontAwesomeIcon
                                                icon={faCalendarDays}
                                            />
                                        }
                                        color={`primary`}
                                        className="me-1"
                                        size="lg"
                                    />
                                    <JpText
                                        type="p-text"
                                        children={`${dateLocalizer.getIntlLongDate(
                                            item.start_datetime
                                        )}`}
                                    />
                                </div>
                            </Col>
                            <Col lg="10">
                                <ChartComponent
                                    minMaxDates={minMaxDates}
                                    data={item?.timeline}
                                    name={dateLocalizer.getIntlLongDate(
                                        item.start_datetime
                                    )}
                                    setIsOpen={setIsOpen}
                                    contractor_id={id}
                                    setQuerySession={setQuerySession}
                                    tickPosition={
                                        index === 0
                                            ? 'top'
                                            : index ===
                                              getTimelineDetails?.data?.length -
                                                  1
                                            ? 'bottom'
                                            : 'none'
                                    }
                                />
                            </Col>
                        </Row>
                    ))
                ) : (
                    <NoData color="light" size="5x" />
                )}
                <ReactPaginate
                    nextLabel=""
                    breakLabel="..."
                    previousLabel=""
                    pageRangeDisplayed={2}
                    forcePage={currentPage}
                    marginPagesDisplayed={2}
                    activeClassName="active"
                    pageClassName="page-item"
                    breakClassName="page-item"
                    nextLinkClassName="page-link"
                    pageLinkClassName="page-link"
                    breakLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextClassName="page-item next-item"
                    previousClassName="page-item prev-item"
                    pageCount={
                        Math.ceil(
                            getTimelineDetails?.count / CONTRACTORS_PER_PAGE
                        ) || 1
                    }
                    onPageChange={page => handlePagination(page)}
                    containerClassName="pagination react-paginate pagination-sm separated-pagination justify-content-end pe-1 mt-1"
                />
            </Card>
            <AppsModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                setQuerySession={setQuerySession}
                data={getAllAppsSession}
            />
        </>
    )
}

export default TimelineDetails
