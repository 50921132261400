import { combineReducers } from 'redux'

// ** Reducers Imports
import layout from './layout'
import navbar from './navbar'
import breadcrumbs from './breadcrumbs'
import getUrlPdf from './getUrlPdf'
import getStatePayroll from './getStatePayroll'
import payrollDetailPosition from './payrollDetailPosition'
import backofficeUser from './user'
import adminRoutes from './routes'
import signContractModal from './signContractModal'
import hasRL from './hasRL'
import projectInfoRow from './projectInfoRow'
import taskInfoRow from './taskInfoRow'


const rootReducer = combineReducers({
    navbar,
    layout,
    breadcrumbs,
    getUrlPdf,
    payrollDetailPosition,
    backofficeUser,
    getStatePayroll,
    adminRoutes,
    signContractModal,
    hasRL,
    projectInfoRow,
    taskInfoRow
})

export default rootReducer
