export const productivityStates = {
    PRODUCTIVE: 'PRODUCTIVE',
    UNPRODUCTIVE: 'UNPRODUCTIVE',
    NEUTRAL: 'NEUTRAL'
}

export const states = {
    ACTIVE: {
        color: 'success',
        iconAvatar: 'fa-solid fa-person-rays'
    },
    PRODUCTIVE: {
        color: 'success',
        iconAvatar: 'fa-solid fa-person-rays'
    },
    UNPRODUCTIVE: {
        color: 'danger',
        iconAvatar: 'fa-solid fa-person-rays'
    },
    NEUTRAL: {
        color: 'primary',
        iconAvatar: 'fa-solid fa-person-rays'
    },
    ON_CALL: {
        color: 'success',
        iconAvatar: 'fa-solid fa-person-rays'
    },
    AFK: {
        color: 'warning',
        iconAvatar: 'fa-solid fa-laptop-slash'
    },
    DISCONNECTED: {
        color: 'light',
        iconAvatar: 'fa-kit fa-solid-plug-slash'
    },
    CLOCK_OUT: {
        color: 'secondary',
        iconAvatar: 'fa-solid fa-arrow-right-from-bracket'
    }
}