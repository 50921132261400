import { Controller } from 'react-hook-form'
import { JpLabel } from '@Intelli/utilities'
import { useTranslation } from 'react-i18next'
import AsyncSelect from 'react-select/async'
import { FormFeedback } from 'reactstrap'

const JpAsyncSelectController = ({
    name,
    title,
    control,
    errors = {},
    loadOptions,
    placeHolder = '',
    optionsController = {},
    optionsAsyncSelect = {},
    errorCustomMsg = '',
    components={},
    multi=false
}) => {
    const { t } = useTranslation()
    return (
        <>
            {title && <JpLabel labelOptions={{ for: name }}>{title}</JpLabel>}
            <Controller
                name={name}
                control={control}
                {...optionsController}
                render={({ field }) => (
                    <AsyncSelect
                        className={`${!!errors[name] && 'is-invalid'}`}
                        classNames={{
                            control: state =>
                                `${!!errors[name] ? 'is-invalid' : ''}`
                        }}
                        hideSelectedOptions
                        defaultOptions 
                        loadOptions={loadOptions}
                        cacheOptions
                        isMulti={multi}
                        components={components}
                        placeholder={
                            placeHolder ||
                            t('JpAsyncSelectController.placeHolder', {
                                ns: 'component'
                            })
                        }
                        noOptionsMessage={() =>
                            t('JpAsyncSelectController.noOptionsMessage', {
                                ns: 'component'
                            })
                        }
                        loadingMessage={() =>
                            t('JpAsyncSelectController.loadingMessage', {
                                ns: 'component'
                            })
                        }
                        {...field}
                        {...optionsAsyncSelect}
                    />
                )}
            />
            <FormFeedback invalid>
                {errors[name]?.label?.message
                    ? t(`${errors[name]?.label?.message}`, {
                          ns: 'errors'
                      })
                    : errorCustomMsg || t(`errors.${errors[name]?.message}`)}
            </FormFeedback>
        </>
    )
}

export default JpAsyncSelectController
