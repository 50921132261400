import React from 'react'
import { Bar } from 'react-chartjs-2'
import 'chartjs-adapter-date-fns'
import {
    Chart,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
} from 'chart.js'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
)

const appColors = {
    PRODUCTIVE: {
        color: 'rgba(40,199,11)'
    },
    UNPRODUCTIVE: {
        color: 'rgba(234,84,85)'
    },
    NEUTRAL: {
        color: 'rgba(1,152,254)'
    },
    AFK: {
        color: 'rgba(255, 159, 67, 1)'
    }
}

function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

const verifyMinHour = date => {
    const dateParsed = moment(date, 'HH:mm:ss').format('HH:mm:ss')
    const lastDateAvailable = moment('02:00:00', 'HH:mm:ss').format('HH:mm:ss')
    if (dateParsed > lastDateAvailable) {
        return moment(date, 'HH:mm:ss').subtract(2, 'hours').format('HH:mm:ss')
    } else {
        return moment(date, 'HH:mm:ss').format('HH:mm:ss')
    }
}

const verifyMaxHour = date => {
    const dateParsed = moment(date, 'HH:mm:ss').format('HH:mm:ss')
    const lastDateAvailable = moment('22:00:00', 'HH:mm:ss').format('HH:mm:ss')
    if (dateParsed < lastDateAvailable) {
        return moment(date, 'HH:mm:ss').add(2, 'hours').format('HH:mm:ss')
    } else {
        return moment(date, 'HH:mm:ss').format('HH:mm:ss')
    }
}

const ChartComponent = ({
    data,
    name,
    setIsOpen,
    contractor_id,
    setQuerySession,
    tickPosition = 'none',
    minMaxDates
}) => {
    const { t } = useTranslation()

    const sortByStartDate = array => {
        return array.sort((a, b) => {
            const dateA = new Date(a.start_datetime)
            const dateB = new Date(b.start_datetime)
            return dateA - dateB
        })
    }
    const sortedArray = sortByStartDate(data)
    const first = sortedArray[0].start_datetime
    const end = sortedArray[sortedArray.length - 1].end_datetime

    // setup
    const chartData = React.useMemo(() => {
        const datasets = data.map((item, index) => {
            const startDate = moment(item.start_datetime).format(
                'YYYY-MM-DD HH:mm:ss'
            )
            const endDate = moment(item.end_datetime).format(
                'YYYY-MM-DD HH:mm:ss'
            )

            let borderEnd = true
            let borderStart = true

            if (!!data[index + 1]) {
                if (
                    moment(data[index + 1].start_datetime).diff(
                        moment(data[index].end_datetime),
                        'minutes'
                    ) === 0 &&
                    data[index + 1].app_type === item.app_type
                ) {
                    borderEnd = false
                }
            }

            if (!!data[index - 1]) {
                if (
                    moment(data[index].start_datetime).diff(
                        moment(data[index - 1].end_datetime),
                        'minutes'
                    ) === 0 &&
                    data[index - 1].app_type === item.app_type
                ) {
                    borderStart = false
                }
            }

            return {
                /* label: item.app_type, */
                data: [
                    {
                        x: [startDate, endDate],
                        y: name
                    }
                ],
                backgroundColor: [
                    appColors[item.app_type]?.color || 'rgba(128, 128, 128,0.3)'
                ],
                // borderRadius: 10,
                borderRadius: {
                    topLeft: borderStart ? 10 : 0,
                    topRight: borderEnd ? 10 : 0,
                    bottomLeft: borderStart ? 10 : 0,
                    bottomRight: borderEnd ? 10 : 0
                },
                borderSkipped: false,
                maxBarThickness: 15
            }
        })

        return {
            /* labels: ['Status'], */
            datasets
        }
    }, [data])

    const handleBarClick = (event, chartElement) => {
        if (chartElement.length > 0) {
            const datasetIndex = chartElement[0].datasetIndex
            const clickedData = data[datasetIndex]

            setQuerySession({
                contractor_id,
                session_id: clickedData.session_id,
                start_datetime: clickedData.start_datetime,
                end_datetime: moment(clickedData.end_datetime)
                    .subtract(1, 'millisecond')
                    .utc()
            })
            setIsOpen(true)
        }
    }

    const handleBarHover = (e, chartElement) => {
        e.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
    }

    const barBackTrack = {
        id: 'barBackTrack',
        beforeDatasetsDraw: (chart, args, plugins) => {
            const {
                ctx,
                data,
                chartArea,
                scales: { x, y }
            } = chart

            const { width, height } = chartArea

            const firstBarData = chart.getDatasetMeta(0).data
            const firstBarStart = firstBarData[0]?.x - firstBarData[0]?.width

            const lastBarData = chart.getDatasetMeta(
                data.datasets.length - 1
            ).data
            const lastBarEnd = lastBarData[lastBarData.length - 1].x

            const barThickness = firstBarData[0]?.height

            ctx.save()

            ctx.fillStyle = 'rgba(128, 128, 128,0.1)'
            ctx.fillRect(
                firstBarStart,
                y.getPixelForValue(0) - barThickness / 2,
                lastBarEnd - firstBarStart,
                barThickness
            )
        }
    }

    const options = {
        indexAxis: 'y',
        animation: false,
        aspectRatio: 15,
        scales: {
            x: {
                ticks: {
                    color:
                        tickPosition === 'none'
                            ? 'rgba(128, 128, 128,0.3)'
                            : 'rgba(128, 128, 128,0.3)',
                    display: tickPosition === 'none' ? false : true,
                    maxRotation: 0,
                    padding: tickPosition === 'none' ? 0 : 0,
                    includeBounds: false,
                    callback: (value, index, values) => {
                        if (tickPosition === 'none') {
                            if (index === values.length - 1 || index === 0) {
                                return null
                            } else {
                                return ''
                            }
                        }
                        return index === values.length - 1 || index === 0
                            ? null
                            : `${moment(value, 'hA').format('H')}hrs`
                    },
                    startAtZero: tickPosition === 'none' ? false : true
                },
                type: 'time',
                time: {
                    unit: 'hour',
                    tooltipFormat: 'YYYY-MM-DD HH:mm:ss' // Formato de fecha para el tooltip,
                },
                min: `${moment(first).format('YYYY-MM-DD')} ${
                    minMaxDates?.minHour
                        ? verifyMinHour(minMaxDates?.minHour)
                        : '00:00:00'
                }`,

                max: `${moment(end).format('YYYY-MM-DD')} ${
                    minMaxDates?.maxHour
                        ? verifyMaxHour(minMaxDates?.maxHour)
                        : '23:59:59'
                }`,

                position: tickPosition !== 'none' ? tickPosition : 'bottom',
                alignToPixels: true,
                grid: {
                    drawTicks: tickPosition === 'none' ? false : true
                }
            },
            y: {
                beginAtZero: true,
                stacked: true,
                display: false
            }
        },
        onHover: handleBarHover,
        onClick: handleBarClick,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                xAlign: 'top',
                yAlign: 'center',
                displayColors: false,
                callbacks: {
                    label: ctx => {
                        // Obtener el índice del dataset y el punto de datos
                        const datasetIndex = ctx.datasetIndex

                        // Obtener la propiedad app_type correspondiente de dataToChart
                        const appType = data[datasetIndex].app_type
                        const appPercent = data[datasetIndex].percent
                        const appMostUsed = data[datasetIndex].app.name

                        const start_datetime = moment(
                            data[datasetIndex].start_datetime
                        ).format('HH:mm')

                        const end_datetime = moment(
                            data[datasetIndex].end_datetime
                        ).format('HH:mm')

                        return `${start_datetime}-${end_datetime} ${t(
                            `views.productivity.states.${appType}`
                        )} : ${Math.round(appPercent)}% ${t(
                            'views.productivity.appMostUsed'
                        )}: ${capitalizeFirstLetter(appMostUsed)}`
                    }
                }
            }
        }
    }

    return (
        <div>
            <Bar data={chartData} options={options} plugins={[barBackTrack]} />
        </div>
    )
}

export default ChartComponent
