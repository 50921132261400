import {
    faArrowRotateLeft,
    faCreditCard,
    faFileArrowDown,
    faTrashCan
} from '@fortawesome/pro-solid-svg-icons'

import {
    JpText,
    dateLocalizer,
    SweetModal,
    JpFilterButtons,
    JpAvatar,
    JpTableAction,
    JpDatePicker,
    SpinnerLoader,
    JpTableActionsCol,
    JpTableTitleName,
    JpCurrencyNumber,
    JpTableV1
} from '@Intelli/utilities'
import { setStatePayroll } from '@src/redux/getStatePayroll'
import AdminAPI from '@src/services/API'
import { useMutation, useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'

import { downloadFile } from '../../utility/Files'
import StatisticsHistorics from './StatisticsHistorics'
import useSocket from '@src/hooks/useSocket'
import usePermissionsPayrolls from '@src/hooks/usePermissionsPayrolls'

export const colorPayrolls = {
    SUCCESS: {
        color: 'success',
        icon: <i class="fak fa-solid-credit-card-circle-check"></i>
    },
    PENDING: {
        color: 'yellow',
        icon: <i class="fak fa-solid-credit-card-circle-info"></i>
    },
    REJECTED: {
        color: 'danger',
        icon: <i class="fak fa-solid-credit-card-circle-xmark"></i>
    },
    VOIDED: {
        color: 'warning',
        icon: <i class="fak fa-solid-credit-card-slash"></i>
    },
    RETURNED: {
        color: 'purple',
        icon: <i class="fak fa-solid-credit-card-circle-arrow-left"></i>
    },
    REQUIRES_ACTION: {
        color: 'light-yellow',
        icon: <i class="fak fa-solid-credit-card-circle-info"></i>
    }
}

const Historical = () => {
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const [start_date, setStartDate] = useState(
        moment().subtract(12, 'months').format('YYYY-MM-DD')
    )
    const [end_date, setEndDate] = useState(moment().format('YYYY-MM-DD'))
    const [currentPage, setCurrentPage] = useState(0)
    const [queries, setQueries] = useState('')
    const [limite, setLimit] = useState(10)
    const [statusType, setStatusType] = useState('')
    const [sortBy, setSortBy] = useState('created_at__desc')

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const API = new AdminAPI()
    const navigate = useNavigate()
    const { hasWriteHistoric } = usePermissionsPayrolls()

    const enumPayrollState = {
        PENDING: 'PENDING',
        APPROVED: 'APPROVED',
        REJECTED: 'REJECTED',
        REQUIRES_ACTION: 'REQUIRES_ACTION'
    }

    const {
        data: getAdminUserInfo,
        isLoading: getAdminUserInfoIsLoading,
        isError: getAdminUserInfoHasError
    } = useQuery(['adminUserInfo'], () => API.getAdminUserInfo())

    const {
        data: payrollsHistoric,
        isError: payrollsHistoricHasError,
        isLoading: payrollsHistoricIsLoading,
        refetch
    } = useQuery(
        [
            'PayrollsHistoric',
            currentPage,
            limite,
            queries,
            statusType,
            start_date,
            end_date,
            sortBy
        ],
        () =>
            API.getPayrollsHistorics({
                offset: currentPage,
                limit: limite,
                query: queries,
                state: statusType,
                created_at__between: `${start_date},${end_date}`,
                order: sortBy
            }),
        { keepPreviousData: true }
    )

    useSocket({
        '/payroll/historic': {
            fn: value => {
                refetch()
            }
        }
    })

    const {
        setValue,
        formState: { errors }
    } = useForm({
        defaultValues: {
            contractor_add_id: ''
        }
    })

    const { mutate: getTokenPayment, isLoading: isLoadingGetTokenPayment } =
        useMutation(data => API.payrollHistoryTransactionTokenPayment(data), {
            onSuccess: (data, transaction_id) => {
                navigate(
                    `/admin/payrolls/pay?checkout_session_id=${data.data.checkout_session_id}&redirect=/admin/payrolls/historics/details/${transaction_id}`
                )
            }
        })

    const { mutate: revertTransaction } = useMutation(
        data => API.revertTransactionHistoric({ data }),
        {
            onSuccess: () => {
                SweetModal(
                    'success',
                    t('Success'),
                    t('modals.revertTransactionSucces'),
                    t('Ok')
                )
                refetch()
            }
        }
    )

    const revert = async (id, state) => {
        /* ANULAR= POR BODY PASAR STATE: 'APPROVED'
RESTABLECER= POR BODY PASAR STATE: 'PENDING' */

        return SweetModal(
            'warning',
            state === enumPayrollState.APPROVED
                ? t('views.historicalPayments.resetPayrollTitle')
                : t('views.historicalPayments.revert'),
            state === enumPayrollState.PENDING
                ? t('views.historicalPayments.resetPayrollText')
                : t('modals.revertTransaction'),
            t('Ok'),
            result => {
                if (result.isConfirmed) {
                    revertTransaction({ id, state })
                }
            },
            { showCancelButton: true, cancelButtonText: t('CANCEL') }
        )
    }

    const downloadShow = ['SUCCESS', 'RETURNED', 'REJECTED']

    const tabCols = [
        {
            name: '',
            grow: '<int de 1-n>',
            minWidth: '50px',
            sortable: true,
            cell: row => (
                <div className="d-flex align-items-center user-info">
                    <div className="d-flex justify-content-center align-items-center">
                        <JpAvatar
                            className="me-1"
                            icon={colorPayrolls[row?.state]?.icon}
                            color={colorPayrolls[row?.state]?.color}
                        />
                    </div>
                </div>
            )
        },
        {
            name: t('views.historicalPayments.transaction'),
            sortable: 'name',
            minWidth: '300px',
            cell: row => (
                <div className="d-flex align-items-center user-info">
                    <div className="text-truncate ">
                        <Link
                            to={`historics/details/${row?.transaction_id}`}
                            className="d-block fw-bold text-truncate"
                            onClick={() =>
                                dispatch(setStatePayroll(row?.state))
                            }
                        >
                            {`#${row?.name}`}
                        </Link>
                    </div>
                </div>
            )
        },
        {
            name: t('views.historicalPayments.date'),
            sortable: 'created_at',
            cell: row => (
                <div className="d-flex align-items-center justify-content-center">
                    <div id={`date${row?.transaction_id}`}>
                        <JpText
                            type="span-table-date"
                            children={dateLocalizer.getIntlLongDate(
                                row?.created_at
                            )}
                        />
                    </div>
                    <UncontrolledTooltip target={`date${row?.transaction_id}`}>
                        {dateLocalizer.getIntlLongDate(row?.created_at)}
                    </UncontrolledTooltip>
                </div>
            )
        },
        {
            name: t('views.historicalPayments.mount'),
            sortable: 'balance',
            cell: row => (
                <>
                    <div id={`balance${row?.transaction_id}`}>
                        <JpCurrencyNumber value={row?.balance} />
                    </div>
                    <UncontrolledTooltip
                        target={`balance${row?.transaction_id}`}
                    >
                        <JpCurrencyNumber value={row?.balance} />
                    </UncontrolledTooltip>
                </>
            )
        },
        {
            name: t('views.historicalPayments.formOfPayment'),
            sortable: 'payment_method',
            minWidth: '250px',
            cell: row => (
                <div className="d-flex align-items-center justify-content-center">
                    {row?.payment_method ? (
                        <JpText
                            type="span-table-text"
                            children={row?.payment_method}
                        />
                    ) : (
                        '-'
                    )}
                </div>
            )
        },
        {
            name: t('views.historicalPayments.state'),
            sortable: 'state',
            cell: row => (
                <>
                    {row?.transaction_id && (
                        <div className="d-flex justify-content-center">
                            <JpAvatar
                                id={`state-${row?.transaction_id}`}
                                color={colorPayrolls[row?.state]?.color}
                                className="cursor-default"
                                content={t(
                                    `views.historicalPayments.${row?.state}`
                                )}
                                initials
                            />
                            <UncontrolledTooltip
                                target={`state-${row?.transaction_id}`}
                            >
                                {t(`views.historicalPayments.${row?.state}`)}
                            </UncontrolledTooltip>
                        </div>
                    )}
                </>
            )
        },
        {
            name: (
                <JpTableTitleName
                    customClass="me-1"
                    text={t('views.documents.contractsTable.actions')}
                />
            ),
            right: true,
            allowOverflow: true,
            cell: row => {
                if (!hasWriteHistoric) return <></>
                return (
                    <JpTableActionsCol
                        children={
                            <>
                                {downloadShow.includes(row?.state) &&
                                    row?.document_media?.location && (
                                        <JpTableAction
                                            icon={faFileArrowDown}
                                            id={`download-${row.transaction_id}`}
                                            tooltipText={t(
                                                'views.historicalPayments.proofOfPayment'
                                            )}
                                            onClick={() => {
                                                SweetModal(
                                                    'success',
                                                    t('Success'),
                                                    '',
                                                    t('Ok')
                                                )
                                                downloadFile(
                                                    row?.document_media
                                                        ?.location,
                                                    row?.document_media?.name
                                                )
                                            }}
                                        />
                                    )}

                                {row?.state === enumPayrollState.PENDING && (
                                    <>
                                        {getAdminUserInfo?.legal_representative
                                            ?.verified === 'VERIFIED' && (
                                            <JpTableAction
                                                icon={faCreditCard}
                                                id={`pay-${row.transaction_id}`}
                                                tooltipText={t(
                                                    'views.historicalPayments.reprocessPayment'
                                                )}
                                                onClick={() => {
                                                    getTokenPayment(
                                                        row.transaction_id
                                                    )
                                                    // navigate(
                                                    //     `/admin/payrolls/historics/details/${row.transaction_id}/logs`
                                                    // )
                                                }}
                                            />
                                        )}

                                        <JpTableAction
                                            icon={faArrowRotateLeft}
                                            id={`reset-${row.transaction_id}`}
                                            tooltipText={t(
                                                'views.historicalPayments.restorePayrollForPayment'
                                            )}
                                            onClick={() => {
                                                revert(
                                                    row?.transaction_id,
                                                    enumPayrollState.APPROVED
                                                )
                                            }}
                                        />

                                        <JpTableAction
                                            icon={faTrashCan}
                                            id={`revert-${row.transaction_id}`}
                                            tooltipText={t(
                                                'views.historicalPayments.revert'
                                            )}
                                            onClick={() => {
                                                revert(
                                                    row?.transaction_id,
                                                    enumPayrollState.PENDING
                                                )
                                            }}
                                        />
                                    </>
                                )}

                                {row?.state ===
                                    enumPayrollState.REQUIRES_ACTION && (
                                    <>
                                        <JpTableAction
                                            icon={faArrowRotateLeft}
                                            id={`reset-${row.transaction_id}`}
                                            tooltipText={t(
                                                'views.historicalPayments.restorePayrollForPayment'
                                            )}
                                            onClick={() => {
                                                revert(
                                                    row?.transaction_id,
                                                    enumPayrollState.APPROVED
                                                )
                                            }}
                                        />

                                        <JpTableAction
                                            icon={faTrashCan}
                                            id={`revert-${row.transaction_id}`}
                                            tooltipText={t(
                                                'views.historicalPayments.revert'
                                            )}
                                            onClick={() => {
                                                revert(
                                                    row?.transaction_id,
                                                    enumPayrollState.PENDING
                                                )
                                            }}
                                        />
                                    </>
                                )}

                                {row?.state === enumPayrollState.REJECTED && (
                                    <>
                                        {getAdminUserInfo?.legal_representative
                                            ?.verified === 'VERIFIED' && (
                                            <JpTableAction
                                                icon={faCreditCard}
                                                id={`pay-${row.transaction_id}`}
                                                tooltipText={t(
                                                    'views.historicalPayments.reprocessPayment'
                                                )}
                                                onClick={() => {
                                                    getTokenPayment(
                                                        row.transaction_id
                                                    )
                                                    // navigate(
                                                    //     `/admin/payrolls/historics/details/${row.transaction_id}/logs`
                                                    // )
                                                }}
                                            />
                                        )}

                                        <JpTableAction
                                            icon={faArrowRotateLeft}
                                            id={`reset-${row.transaction_id}`}
                                            tooltipText={t(
                                                'views.historicalPayments.restorePayrollForPayment'
                                            )}
                                            onClick={() => {
                                                revert(row?.transaction_id)
                                            }}
                                        />

                                        <JpTableAction
                                            icon={faTrashCan}
                                            id={`revert-${row.transaction_id}`}
                                            tooltipText={t(
                                                'views.historicalPayments.revert'
                                            )}
                                            onClick={() => {
                                                revert(row?.transaction_id)
                                            }}
                                        />
                                    </>
                                )}
                            </>
                        }
                    />
                )
            }
        }
    ]

    const filters = [
        {
            icon: 'fak fa-solid-credit-card-circle-check',
            color: 'success',
            state: 'SUCCESS',
            text: t('views.historicalPayments.SUCCESS')
        },
        {
            icon: 'fak fa-solid-credit-card-circle-info',
            color: 'yellow',
            state: 'PENDING,REQUIRES_ACTION',
            text: t('views.historicalPayments.PENDING')
        },
        {
            icon: 'fak fa-solid-credit-card-circle-xmark',
            color: 'danger',
            state: 'REJECTED',
            text: t('views.historicalPayments.REJECTED')
        },
        {
            icon: 'fak fa-solid-credit-card-slash',
            color: 'warning',
            state: 'VOIDED',
            text: t('views.historicalPayments.VOIDED')
        },
        {
            icon: 'fak fa-solid-credit-card-circle-arrow-left',
            color: 'purple',
            state: 'RETURNED',
            text: t('views.historicalPayments.RETURNED')
        }
    ]

    const TabActions = () => {
        return (
            <Row className="justify-content-md-between align-items-center">
                <Col
                    sm="5"
                    md="4"
                    lg="4"
                    className="d-flex justify-content-start pe-1 table-filters"
                >
                    <JpDatePicker
                        id="contractStartDate"
                        maxDate={moment(end_date).format('YYYY-MM-DD')}
                        value={start_date}
                        setPicker={date =>
                            setStartDate(date.format('YYYY-MM-DD'))
                        }
                        className={'enabled-joobpay'}
                    />

                    <JpDatePicker
                        id="contractEndDate"
                        value={end_date}
                        minDate={moment(start_date).format('YYYY-MM-DD')}
                        maxDate={moment().format('YYYY-MM-DD')}
                        setPicker={date =>
                            setEndDate(date.format('YYYY-MM-DD'))
                        }
                        className={'enabled-joobpay ms-1'}
                    />
                </Col>

                <Col
                    sm="7"
                    md="8"
                    lg="8"
                    className="d-none d-xxl-flex justify-content-end table-filters"
                >
                    <JpFilterButtons
                        filters={filters}
                        state={statusType}
                        setStatusType={setStatusType}
                        setCurrentPage={setCurrentPage}
                    />
                </Col>
            </Row>
        )
    }

    const handlePageChange = ({ page, limit, query, sort }) => {
        setCurrentPage(page)
        setLimit(limit)
        setQueries(query)
        if (sort) setSortBy(sort)
    }

    if (payrollsHistoricIsLoading || isLoadingGetTokenPayment)
        return <SpinnerLoader />

    return (
        <div>
            <StatisticsHistorics />

            <div className="mt-2">
                <JpTableV1
                    cols={tabCols}
                    fetcher={handlePageChange}
                    data={payrollsHistoric?.data}
                    total={payrollsHistoric?.count}
                    loading={payrollsHistoricIsLoading}
                    actions={<TabActions />}
                    offset={currentPage}
                />
            </div>
        </div>
    )
}

export default Historical
