import { JpButtonGroup, HasPermissionModules } from '@Intelli/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap'
import searchFirstModuleActiveFunction from '@src/utility/searchFirstModuleActiveFunction'
import { useApplicationModules } from '@src/hooks/ApplicationModules.hook'
import HoursDays from './time/HoursDays'
import ProductivityVsTimeDays from './time/ProductivityVsTimeDays'
import { useSearchParams } from 'react-router-dom'
import usePermissionTimePermissions from '@src/hooks/usePermissionTimePermissions'
import PermissionsDetails from './time/PermissionsDetails'

const TimesDetailsContractor = () => {
    const { modules } = useApplicationModules()
    const { t } = useTranslation()
    const [params, setParams] = useSearchParams()

    const [currentPage, setCurrentPage] = useState(
        searchFirstModuleActiveFunction('TIME')
    )
    const [hasHours, setHasHours] = useState(
        HasPermissionModules.Read(modules, ['TIME', 'TIME-HOURS'])
    )
    const [hasTimeProductivity, setHasTimeProductivity] = useState(
        HasPermissionModules.Read(modules, ['TIME', 'TIME-PRODUCTIVITY'])
    )
    const isProfile = window.location.href.includes('profile')

    useEffect(() => {
        /* setHasHours(HasPermissionModules.Read(modules, ['TIME', 'TIME-HOURS']))
        setHasTimeProductivity(
            HasPermissionModules.Read(modules, ['TIME', 'TIME-PRODUCTIVITY'])
        ) */
        if (params.get('detail')) {
            setCurrentPage(parseInt(params.get('detail')))
        }
    }, [])

    const { hasTimePermissions } = usePermissionTimePermissions()

    const PageBody = () => {
        if (currentPage === 1) return <HoursDays />
        if (currentPage === 2) return <ProductivityVsTimeDays />
        if (currentPage === 3) return <PermissionsDetails />
    }

    return (
        <>
            <Row>
                <Col className="text-start mb-1">
                    <JpButtonGroup>
                        {hasHours && (
                            <>
                                <Button
                                    data-testid="pending-btn"
                                    color="info"
                                    onClick={() => {
                                        setCurrentPage(1)
                                        setParams(prev => {
                                            return {
                                                ...(prev.get('page')
                                                    ? { page: prev.get('page') }
                                                    : {}),
                                                detail: 1
                                            }
                                        })
                                    }}
                                    active={currentPage === 1}
                                    outline={currentPage !== 1}
                                    id="hours"
                                >
                                    <FontAwesomeIcon
                                        icon={'fa-solid fa-clock-three'}
                                    />
                                    {!isProfile && (
                                        <span className="align-middle ms-1">
                                            {t('hours')}
                                        </span>
                                    )}
                                    {isProfile && <UncontrolledTooltip
                                        target={'hours'}
                                        placement="bottom"
                                    >
                                        {t('hours')}
                                    </UncontrolledTooltip>}
                                </Button>
                            </>
                        )}

                        {hasTimeProductivity && (
                            <>
                                <Button
                                    data-testid="pending-btn"
                                    color="info"
                                    onClick={() => {
                                        setCurrentPage(2)
                                        setParams(prev => {
                                            return {
                                                ...(prev.get('page')
                                                    ? { page: prev.get('page') }
                                                    : {}),
                                                detail: 2
                                            }
                                        })
                                    }}
                                    active={currentPage === 2}
                                    outline={currentPage !== 2}
                                    id="prodvstime"
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-chart-line-up" />
                                    {!isProfile && (
                                        <span className="align-middle ms-1">
                                            {t(
                                                'views.time.graphs.productivityVsTime',
                                                {
                                                    ns: 'global'
                                                }
                                            )}
                                        </span>
                                    )}
                                    {isProfile && <UncontrolledTooltip
                                        target={'prodvstime'}
                                        placement="bottom"
                                    >
                                        {t(
                                            'views.time.graphs.productivityVsTimeTooltip',
                                            {
                                                ns: 'global'
                                            }
                                        )}
                                    </UncontrolledTooltip>}
                                </Button>
                            </>
                        )}

                        {hasTimePermissions && (
                            <>
                                <Button
                                    className="d-flex align-items-center"
                                    color="info"
                                    onClick={() => {
                                        setCurrentPage(3)
                                        setParams(prev => {
                                            return {
                                                ...(prev.get('page')
                                                    ? { page: prev.get('page') }
                                                    : {}),
                                                detail: 3
                                            }
                                        })
                                    }}
                                    active={currentPage === 3}
                                    outline={currentPage !== 3}
                                    id="news"
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-newspaper" />
                                    {!isProfile && (
                                        <span className="align-middle ms-1">
                                            {t(
                                                'views.settings.calendar.permissions'
                                            )}
                                        </span>
                                    )}
                                </Button>

                               {isProfile && <UncontrolledTooltip
                                    target={'news'}
                                    placement="bottom"
                                >
                                    {t('views.settings.calendar.permissions')}
                                </UncontrolledTooltip>}
                            </>
                        )}
                    </JpButtonGroup>
                </Col>
            </Row>
            <div className="mb-5">
                <PageBody />
            </div>
        </>
    )
}

export default TimesDetailsContractor
