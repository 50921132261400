import { createSlice } from '@reduxjs/toolkit'

const initialState = ''

export const getStatePayrollSlice = createSlice({
  name: 'getStatePayroll',
  initialState,
  reducers: {
    setStatePayroll: (state, action) => {
      return (state = action.payload)
    }
  }
})

export const { setStatePayroll } = getStatePayrollSlice.actions

export default getStatePayrollSlice.reducer
