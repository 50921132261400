export const leftButtonStyle = {
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    borderBottomLeftRadius: '0px'
}
export const rightButtonStyle = {
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    borderBottomLeftRadius: '10px'
}

export const rightButtonStyleNew = {
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '0px',
    borderBottomLeftRadius: '00px'
}

export const leftButtonStyleNew = {
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '00px',
    borderBottomRightRadius: '8px',
    borderBottomLeftRadius: '00px'
}


