import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    task: {},
    isLoading: true,
}

export const taskInfoRow = createSlice({
    name: 'taskInfoRow',
    initialState,
    reducers: {
        setTaskInfoRow: (state, action) => {
            return (state = action.payload)
        }
    }
})

export const { setTaskInfoRow } = taskInfoRow.actions

export default taskInfoRow.reducer
