import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormFeedback, Input, InputGroup } from 'reactstrap'

const JpInputControllerCustom = ({
    type = '',
    name,
    title,
    control,
    errors = {},
    optionsController = {},
    optionsInput = {},
    ns = 'global',
    style = {},
    placeholder = '',
    customButtons,
    className = '',
    additionalComponent = undefined,
    customInput = null
}) => {
    const { t } = useTranslation()

    return (
        <div className="d-flex flex-column">
            <Controller
                name={name}
                control={control}
                {...optionsController}
                render={({ field }) => (
                    <InputGroup>
                        {customInput ? (
                            customInput
                        ) : (
                            <Input
                                autoComplete="off"
                                placeholder={placeholder}
                                style={style}
                                id={name}
                                type={type}
                                invalid={!!errors[name]}
                                {...field}
                                {...optionsInput}
                            />
                        )}

                        {customButtons && customButtons}
                    </InputGroup>
                )}
            />
            <div>{additionalComponent && additionalComponent}</div>
            {!!errors[name] && (
                <span className={`text-danger`}>
                    {t(errors[name]?.message, { ns })}
                </span>
            )}
        </div>
    )
}

export default JpInputControllerCustom
