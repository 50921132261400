import { socket } from '@src/services/SocketConfig'
import { useEffect, useState } from 'react'

/* Example: 
                useSocket({
                  '/productivity/dashboard': { //TOPIC
                    time: 1, // para el setTimeout = tiempo de agrupacion de funciones para ejecutar la ultima (esta funcion se va a ejecutar cada 1 milisegundo como minimo en caso de que llegue una notificacion)
                      fn: value => { //FN: FUNCION A EJECUTAR CUANDO LLEGUE UNA NOTIFICACION
                              productivityDashboardRefetch()
                      }
                  }, [date]), //ARRAY DE DEPENDENCIAS
                  
*/

const useSocket = (callbacks, dependency = []) => {
    useEffect(() => {
        const idSocket = socket.subscribe(callbacks)

        return () => {
            socket.unsubscribe(idSocket)
        }
    }, dependency)
}

export default useSocket
