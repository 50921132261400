import {
    JpTableV1,
    JpAvatar,
    JpDatePicker,
    parseMinutesToHours,
    formatDatesUtc,
    JpFilterButtons,
    JpAdvanceCard,
    JpStatsIconCard
} from '@Intelli/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AdminAPI from '@src/services/API'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Badge, Col, Row } from 'reactstrap'
import useSocket from '@src/hooks/useSocket'
import { productivityStatesIcons } from '@src/constants/icons/productivityStatesIcons'
import { productivityStates } from '@src/constants/states/productivity/productivityStates'
import disabledRefetchSocket from '@src/utility/disabledRefetchSocket'

const TabActions = ({
    start_date,
    setStartDate,
    end_date,
    setEndDate,
    filters,
    setType,
    type,
    setCurrentPage
}) => {
    return (
        <>
            <Col
                sm="5"
                md="4"
                lg="4"
                className="d-flex justify-content-start pe-1 table-filters"
            >
                <JpDatePicker
                    id="start_date"
                    value={start_date}
                    setPicker={date => {
                        setCurrentPage(0)
                        setStartDate(date.format('YYYY-MM-DD'))
                    }}
                    className={'enabled-joobpay'}
                    maxDate={end_date}
                />
                <JpDatePicker
                    id="end_date"
                    value={end_date}
                    setPicker={date => {
                        setCurrentPage(0)
                        setEndDate(date.format('YYYY-MM-DD'))
                    }}
                    className={'enabled-joobpay ms-1'}
                    maxDate={moment().format('YYYY-MM-DD')}
                    minDate={start_date}
                />
            </Col>
            <JpFilterButtons
                filters={filters}
                state={type}
                setStatusType={setType}
                setCurrentPage={setCurrentPage}
            />
        </>
    )
}

const AppsDetail = () => {
    const [start_date, setStartDate] = useState(moment().format('YYYY-MM-DD'))
    const [end_date, setEndDate] = useState(moment().format('YYYY-MM-DD'))
    const [currentPage, setCurrentPage] = useState(0)
    const [limit, setLimit] = useState(10)
    const [queries, setQueries] = useState('')
    const [sortBy, setSortBy] = useState('app_time__desc')
    const [type, setType] = useState('')
    const API = new AdminAPI()
    const { id } = useParams()
    const { t } = useTranslation()

    const {
        data: appsData,
        isLoading: appsIsLoading,
        refetch: appsDataRefetch
    } = useQuery(
        [
            'detailed-apps',
            id,
            currentPage,
            limit,
            queries,
            sortBy,
            start_date,
            end_date,
            type
        ],
        () =>
            API.getContractorApps(id, {
                limit,
                offset: currentPage,
                query: queries,
                order: sortBy,
                app_type: type,
                /* start_datetime__between: `${start_date} 00:00:00,${end_date} 23:59:59` */
                start_datetime__between: `${formatDatesUtc.getDateStringtoUTC(
                    start_date
                )},${formatDatesUtc.getDateStringtoUTC(
                    moment(end_date).add(1, 'days').format('YYYY-MM-DD')
                )}`
            }),
        {
            keepPreviousData: true
        }
    )

    const {
        data: mostUsedData,
        isLoading: mostUsedIsLoading,
        refetch: mostUsedDataRefetch
    } = useQuery(['most-used-apps'], () =>
        API.getMostUsedAppsPerType(id, {
            date__between: `${formatDatesUtc.getDateStringtoUTC(
                start_date
            )},${formatDatesUtc.getDateStringtoUTC(
                moment(end_date).add(1, 'days').format('YYYY-MM-DD')
            )}`
        })
    )

    const {
        data: getContractorProductivityChartsData,
        isLoading: getContractorProductivityChartsIsLoading,
        refetch: contractorProductivityRefecth
    } = useQuery(['getContractorProductivityChartsApp', id], () =>
        API.getContractorProductivityCharts(id, {
            date__between: `${formatDatesUtc.getDateStringtoUTC(
                start_date
            )},${formatDatesUtc.getDateStringtoUTC(
                moment(end_date).add(1, 'days').format('YYYY-MM-DD')
            )}`
        })
    )

    useEffect(() => {
        mostUsedDataRefetch()
        contractorProductivityRefecth()
    }, [start_date, end_date])

    useSocket(
        disabledRefetchSocket(end_date)
            ? {
                  [`/productivity/contractors/${id}/charts/top_apps_by_type`]: {
                      fn: value => {
                          mostUsedDataRefetch()
                      }
                  },
                  [`/productivity/contractors/${id}/apps`]: {
                      fn: value => {
                          appsDataRefetch()
                      }
                  },
                  [`/productivity/contractors/${id}/charts/contractor_times`]: {
                      fn: value => {
                          contractorProductivityRefecth()
                      }
                  }
              }
            : {},
        [end_date]
    )

    const colorApps = {
        NEUTRAL: 'info',
        UNPRODUCTIVE: 'danger',
        PRODUCTIVE: 'green'
    }

    const tabCols = [
        {
            name: t('views.productivity.application'),
            sortable: 'app_name',
            compact: true,
            minWidth: '200px',
            wrap: true,
            cell: row => (
                <div className="d-flex align-items-center">
                    <JpAvatar
                        {...(row?.app_location
                            ? { img: row?.app_location }
                            : {})}
                        imgClassName="cursor-default"
                        icon={<FontAwesomeIcon icon="window" />}
                        color="light-info"
                    />
                    <span className="ms-1 d-block fw-bold">
                        {row?.app_name}
                    </span>
                </div>
            )
        },
        {
            name: t('views.productivity.percentageOfUse'),
            sortable: 'percent',
            selector: row => `${row?.percent ? row?.percent : '-'}%`
        },
        {
            name: t('views.productivity.usageTime'),
            sortable: 'app_time',
            selector: row => parseMinutesToHours(row?.app_time)
        },
        {
            name: t('type'),
            sortable: 'app_type',
            cell: row => (
                <Badge color={`light-${colorApps[row.app_type]}`} pill>
                    {t(
                        `views.settings.productivity.${row.app_type.toLowerCase()}`
                    )}
                </Badge>
            )
        }
    ]

    const handlePageChange = ({ page, limit, query, sort }) => {
        setCurrentPage(page)
        setLimit(limit)
        setQueries(query)
        if (sort) setSortBy(sort)
    }

    const filters = [
        {
            icon: productivityStatesIcons.PRODUCTIVE.icon,
            color: `light-${productivityStatesIcons.PRODUCTIVE.color}`,
            state: productivityStates.PRODUCTIVE,
            text: t('views.settings.productivity.productive')
        },
        {
            icon: productivityStatesIcons.NEUTRAL.icon,
            color: `light-${productivityStatesIcons.NEUTRAL.color}`,
            state: productivityStates.NEUTRAL,
            text: t('views.settings.productivity.neutral')
        },
        {
            icon: productivityStatesIcons.UNPRODUCTIVE.icon,
            color: `light-${productivityStatesIcons.UNPRODUCTIVE.color}`,
            state: productivityStates.UNPRODUCTIVE,
            text: t('views.settings.productivity.unproductive')
        }
    ]

    const appsList = [
        { data: mostUsedData?.productive[0], type: 'PRODUCTIVE' },
        { data: mostUsedData?.neutral[0], type: 'NEUTRAL' },
        { data: mostUsedData?.unproductive[0], type: 'UNPRODUCTIVE' }
    ]


    return (
        <>
            <Row className="mb-1">
                <Col lg="3" sm="6" xs="12" className=" mt-1 mt-lg-0">
                    <JpStatsIconCard
                        loading={getContractorProductivityChartsIsLoading}
                        className="h-0"
                        title={t('views.productivity.productive')}
                        stat={`${parseMinutesToHours(
                            getContractorProductivityChartsData?.productive
                                ?.time
                        )} (${Math.round(
                            getContractorProductivityChartsData?.productive
                                ?.percent_work_time
                        )}%)`}
                        statSize="sm"
                        icon={
                            <JpAvatar
                                icon={
                                    <i
                                        class={
                                            productivityStatesIcons.PRODUCTIVE
                                                .icon
                                        }
                                    ></i>
                                }
                                color={productivityStatesIcons.PRODUCTIVE.color}
                            />
                        }
                        iconBgColor={productivityStatesIcons.PRODUCTIVE.color}
                        iconSize="xl"
                        iconDirection="left"
                        subtitle={t('views.time.graphs.lastPeriod')}
                        subtitlePercentage={
                            getContractorProductivityChartsData?.productive
                                ?.percent_last
                        }
                    />
                </Col>

                <Col lg="3" sm="6" xs="12" className=" mt-1 mt-lg-0">
                    <JpStatsIconCard
                        loading={getContractorProductivityChartsIsLoading}
                        title={t('views.productivity.neutral')}
                        stat={`${parseMinutesToHours(
                            getContractorProductivityChartsData?.neutral?.time
                        )} (${Math.round(
                            getContractorProductivityChartsData?.neutral
                                ?.percent_work_time
                        )}%)`}
                        statSize="sm"
                        icon={
                            <JpAvatar
                                icon={
                                    <i
                                        class={
                                            productivityStatesIcons.NEUTRAL.icon
                                        }
                                    ></i>
                                }
                                color={productivityStatesIcons.NEUTRAL.color}
                            />
                        }
                        iconBgColor="light-info"
                        iconSize="xl"
                        iconDirection="left"
                        subtitle={t('views.time.graphs.lastPeriod')}
                        subtitlePercentage={
                            getContractorProductivityChartsData?.neutral
                                ?.percent_last
                        }
                    />
                </Col>

                <Col lg="3" sm="6" xs="12" className=" mt-1 mt-lg-0">
                    <JpStatsIconCard
                        swapBgColor={true}
                        loading={getContractorProductivityChartsIsLoading}
                        title={t('views.productivity.unproductive')}
                        stat={`${parseMinutesToHours(
                            getContractorProductivityChartsData?.unproductive
                                ?.time
                        )} (${Math.round(
                            getContractorProductivityChartsData?.unproductive
                                ?.percent_work_time
                        )}%)`}
                        statSize="sm"
                        icon={
                            <JpAvatar
                                icon={
                                    <i
                                        class={
                                            productivityStatesIcons.UNPRODUCTIVE
                                                .icon
                                        }
                                    ></i>
                                }
                                color={
                                    productivityStatesIcons.UNPRODUCTIVE.color
                                }
                            />
                        }
                        iconBgColor={productivityStatesIcons.UNPRODUCTIVE.color}
                        iconSize="xl"
                        iconDirection="left"
                        subtitle={t('views.time.graphs.lastPeriod')}
                        subtitlePercentage={
                            getContractorProductivityChartsData?.unproductive
                                ?.percent_last
                        }
                    />
                </Col>

                <Col lg="3" sm="6" xs="12" className=" mt-1 mt-lg-0">
                    <JpAdvanceCard
                        loading={mostUsedIsLoading}
                        bodyText={false}
                        maxShown={3}
                        title={t('views.productivity.mostUsedApps')}
                        data={appsList?.map(item => {
                            return {
                                id: item?.data?.app?.id,
                                name: item?.data?.app?.name,
                                time: item?.data?.time,
                                percent: item?.data?.percent,
                                media: { location: item?.data?.app?.location },
                                color: productivityStatesIcons[item?.type]
                                    ?.color
                            }
                        })}
                    />
                </Col>
            </Row>
            {/* <DetailsChartsCards /> */}
            <JpTableV1
                data={appsData?.data}
                total={appsData?.count}
                cols={tabCols}
                loading={appsIsLoading}
                fetcher={handlePageChange}
                offset={currentPage}
                options={{
                    actionsClassName:
                        'd-flex flex-row justify-content-between mt-1 mt-md-0'
                }}
                actions={
                    <TabActions
                        start_date={start_date}
                        end_date={end_date}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        filters={filters}
                        setType={setType}
                        type={type}
                        setCurrentPage={setCurrentPage}
                    />
                }
            />
        </>
    )
}

export default AppsDetail
