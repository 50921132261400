import { parseName } from '@src/utility/Utils'
import { useTranslation } from 'react-i18next'
import { UncontrolledTooltip } from 'reactstrap'
import { parseMinutesToHours, JpText } from '@Intelli/utilities'

const IconEditedWithTooltip = ({
    id,
    first_name,
    last_name,
    created_at,
    note,
    work_time,
    difference,
    text = ''
}) => {
    const { t } = useTranslation()

    return (
        <>
            <JpText
                id={id}
                type="span-table-text"
                className="text-yellow"
                children={text}
            />
            <UncontrolledTooltip target={id}>
                <div className="d-flex flex-column">
                    <span>{`${t('views.time.modifiedBy')}: ${parseName(
                        first_name,
                        last_name
                    )}`}</span>
                    <span>{`${t(
                        'views.documents.documentsTable.date'
                    )}: ${created_at}`}</span>
                    {note ? (
                        <span>{`${t('views.time.reason')}: ${note}`}</span>
                    ) : (
                        ''
                    )}
                </div>
                <div className="d-flex flex-column mt-75">
                    <span>{`${t('views.time.timeWorked')}: ${work_time}`}</span>
                    {Math.floor(difference) > 0 ? ( //Si son segundos (menor que 1) no se muestra el tiempo
                        <span>
                            {`${t(
                                'views.time.missingTime'
                            )}: ${parseMinutesToHours(difference)}`}
                        </span>
                    ) : (
                        ''
                    )}
                </div>
            </UncontrolledTooltip>
        </>
    )
}

export default IconEditedWithTooltip
