import { createSlice } from '@reduxjs/toolkit'

// const initialState = false

const initialState = {
    PENDING: 0,
    VERIFIED: 0,
    REJECTED: 0
}

export const hasRL = createSlice({
    name: 'hasRL',
    initialState,
    reducers: {
        setHasRL: (state, action) => {
            return (state = action.payload)
        }
    }
})

export const { setHasRL } = hasRL.actions

export default hasRL.reducer
