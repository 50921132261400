import { useRef, useState } from 'react'
import { Row, Col, FormFeedback, Input, UncontrolledTooltip } from 'reactstrap'
import { downloadFile } from '../../../utility/Files'
import '../../../@core/scss/react/libs/flatpickr/flatpickr.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFileArrowDown,
    faFileArrowUp,
    faFileLines
} from '@fortawesome/pro-regular-svg-icons'
import {
    faPlus,
    faTrashCan,
    faChartSimple,
    faAlarmClock,
    faListCheck,
    faPaperclipVertical
} from '@fortawesome/pro-solid-svg-icons'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
    JpButton,
    JpText,
    JpLabel,
    dateLocalizer,
    JpFileModal,
    SweetModal,
    JpTableTitleName,
    JpAvatar,
    JpTableAction,
    SpinnerLoader,
    JpTableV1
} from '@Intelli/utilities'
import { Controller, useForm } from 'react-hook-form'
import AdminAPI from '@src/services/API'
import { useMutation, useQuery } from '@tanstack/react-query'
import AsyncSelect from 'react-select/async'
import { useDispatch } from 'react-redux'
import { setUrlPdf } from '../../../redux/getUrlPdf'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import JpAsyncSelectController from '@src/components/input/JpAsyncSelectController'

const DocumentsTab = ({ data, updater, userId, hasWritePermission }) => {
    const navigate = useNavigate()
    const fp = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const [files, setFiles] = useState([])
    const [fileInvalid, setFileInvalid] = useState(false)
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const [isReplace, setReplaceOpen] = useState(false)
    const [replaceFile, setReplaceFile] = useState([])
    const [uid, setUid] = useState('')
    const [name, setName] = useState(false)
    const [hasFile, setHasFile] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [queries, setQueries] = useState('')
    const [limite, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('start_date__desc')

    const [currentPageDoc, setCurrentPageDoc] = useState(0)
    const [queriesDoc, setQueriesDoc] = useState('')
    const [limiteDoc, setLimitDoc] = useState(10)
    const [sortByDoc, setSortByDoc] = useState('created_at__desc')

    const { id } = useParams()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const API = new AdminAPI()

    let label = null

    const requiredName = (input = label) =>
        input?.name && ['OTHER', 'STUDY_CERTIFICATE'].includes(input.name)

    const statesDocuments = {
        LOADED: {
            color: 'success'
        },
        VERIFIED: {
            color: 'success'
        },
        PENDING: {
            color: 'yellow'
        },
        EXPIRED: {
            color: 'danger'
        },
        REJECTED: {
            color: 'danger'
        },
        VERIFICATION: {
            color: 'warning'
        }
    }

    const documentsSchema = yup
        .object()
        .shape({
            document_type_id: yup.object().nullable().required('InputRequired'),
            name: yup.string().when('document_type_id', {
                is: document_type_id => requiredName(),
                then: yup.string().required('InputRequired')
            })
        })
        .test(() => {
            if (files.length === 0) {
                setFileInvalid(true)
                return false
            }
            setFileInvalid(false)
            return true
        })

    const documentTypesWithName = [
        'Otros',
        'Certificado de Estudio',
        'Cuentas por Cobrar',
        'Others',
        'Study Certificate',
        'Account Receivable',
        'OTHER',
        'STUDY_CERTIFICATE',
        'ACCOUNT_RECEIVABLE'
    ]

    const typeIcon = {
        CLASSIC: faChartSimple,
        TIME: faAlarmClock,
        TASKS: faListCheck,
        addendum: faPaperclipVertical
    }

    const {
        control,
        reset,
        getValues,
        setValue,
        watch,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues: {
            document_type_id: '',
            name: ''
        },
        resolver: yupResolver(documentsSchema)
    })

    label = watch('document_type_id')

    const { mutate: deleteDocument } = useMutation(
        data => API.deleteContractorDocument(data),
        {
            onSuccess: () => {
                SweetModal(
                    'success',
                    t('Success'),
                    t('modals.deleted'),
                    t('Ok')
                )
                setShouldUpdate(!shouldUpdate)
            }
        }
    )

    const { mutate: postDocument, isLoading: postDocumentIsLoading } =
        useMutation(data => API.postDocument(data), {
            onSuccess: () => {
                SweetModal(
                    'success',
                    `${t('modals.success')}`,
                    `${t('modals.uploaded')}`,
                    t('Ok')
                )
                reset()
                setFiles([])
                setShouldUpdate(!shouldUpdate)
                setIsOpen(false)
            }
        })

    const {
        data: contractsProfile,
        isError: contractsProfileHaserror,
        isLoading: contractsProfileIsLoading,
        refetch: getContractsProfile
    } = useQuery(
        ['contractsProfile', userId, currentPage, queries, limite, sortBy],
        () =>
            API.getContracts(userId, {
                query: queries,
                offset: currentPage,
                limit: limite,
                order: sortBy
            })
    )

    const { data: documentProfile, isLoading: documentIsLoading } = useQuery(
        [
            'getDocumentsProfile',
            id,
            currentPageDoc,
            queriesDoc,
            limiteDoc,
            sortByDoc,
            shouldUpdate
        ],
        () =>
            API.getContractorDocuments(id, {
                query: queriesDoc,
                offset: currentPageDoc,
                limit: limiteDoc,
                order: sortByDoc
            })
    )

    const {
        data: documentsType,
        isError: documentsTypeError,
        isLoading: documentsTypeIsLoading
    } = useQuery(['typeDocuments'], () => API.getDocumentsType())

    const del = async data => {
        return SweetModal(
            'warning',
            t('modals.deleteDocTitle'),
            t('modals.deleteDoc'),
            t('Ok'),
            result => {
                if (result.isConfirmed) {
                    deleteDocument(data)
                }
            },
            { showCancelButton: true, cancelButtonText: t('CANCEL') }
        )
    }

    const statusContractColor = {
        ACTIVE_CONTRACT: {
            color: 'success'
        },
        INACTIVE_CONTRACT: {
            color: 'danger'
        }
    }

    const contractType = {
        TIME: {
            icon: faAlarmClock,
            color: 'success'
        },
        CLASSIC: {
            icon: faChartSimple,
            color: 'primary'
        },
        TASKS: {
            icon: faListCheck,
            color: 'warning'
        }
    }

    const tabColsContracts = [
        {
            id: 'avatar_column',
            name: '',
            sortable: false,
            compact: true,
            width: '40px',
            cell: row => (
                <JpAvatar
                    className="ms-1"
                    icon={
                        <FontAwesomeIcon
                            icon={typeIcon[row?.contract_type_name]}
                        />
                    }
                    color={`light-${row?.color}`}
                />
            )
        },
        {
            name: t('views.documents.contractsTable.documentsTitle'),
            sortable: 'contract_number',
            cell: row => (
                <div
                    className={`d-flex align-items-center justify-content-center ${
                        row?.contractor_status != 'DISABLED' && 'user-info'
                    }`}
                >
                    <div className="text-truncate d-flex align-items-center">
                       {/*  {row?.contractor_status != 'DISABLED' ? ( */}
                            <Link
                                to={`/admin/contractors/profile/contract-preview/${row?.contractor_id}`}
                                className="d-block fw-bold text-truncate"
                                onClick={() =>{}
                                    /* dispatch(
                                        setUrlPdf({
                                            location:
                                                row.contract_signed_media
                                                    .location,
                                            name: row?.client_name
                                        })
                                    ) */
                                }
                            >
                                {row?.contract_number.substring(0, 6)}
                            </Link>
                       {/*  ) : (
                            <span>{row?.contract_number.substring(0, 6)}</span>
                        )} */}
                    </div>
                </div>
            )
        },
        {
            name: t('views.documents.contractsTable.contractType'),
            sortable: 'contract_type_name',
            cell: row => {
                return (
                    <>
                        {row?.contract_type_name ? (
                            <>
                                <JpAvatar
                                    id={`contract${row?.contractor_id}`}
                                    icon={
                                        <FontAwesomeIcon
                                            icon={
                                                contractType[
                                                    row?.contract_type_name
                                                ]?.icon
                                            }
                                        />
                                    }
                                    color={
                                        contractType[row?.contract_type_name]
                                            ?.color
                                    }
                                />
                                <UncontrolledTooltip
                                    placement="top"
                                    target={`contract${row?.contractor_id}`}
                                >
                                    {t(
                                        `views.settings.templates.types.${row.contract_type_name}`
                                    )}
                                </UncontrolledTooltip>
                            </>
                        ) : (
                            <p className="m-0">-</p>
                        )}
                    </>
                )
            }
        },
        {
            name: t('views.documents.contractsTable.contractStatus'),
            sortable: 'contract_status',
            grow: 2,
            cell: row => {
                return (
                    <div className="d-flex align-items-center justify-content-center text-green">
                        <span
                            className={
                                row?.contractor_status != 'DISABLED'
                                    ? `text-${
                                          statusContractColor[
                                              row?.contract_status
                                          ]?.color || 'warning'
                                      }`
                                    : 'text-secondary'
                            }
                        >
                            {row?.contractor_status != 'DISABLED'
                                ? t(`contract_status.${row?.contract_status}`)
                                : t(`contract_status.INACTIVE_CONTRACT`)}
                        </span>
                    </div>
                )
            }
        },
        {
            name: (
                <JpTableTitleName
                    customClass="me-1"
                    text={t('views.documents.contractsTable.actions')}
                />
            ),
            right: true,

            allowOverflow: true,
            cell: row => {
                return (
                    row.contract_signed_location && (
                        <JpTableAction
                            icon={faFileArrowDown}
                            id={`download-${row?.contract_number}`}
                            tooltipText={t(
                                'views.documents.documentsTable.downloadButton'
                            )}
                            onClick={() => {
                                downloadFile(
                                    row.contract_signed_media.location,
                                    row.contract_signed_media.name
                                )
                            }}
                        />
                    )
                )
            }
        }
    ]

    const tabColsDocuments = [
        {
            width: '50px',
            cell: row => (
                <div className="d-flex justify-content-center align-items-center">
                    <JpAvatar
                        className="me-1"
                        icon={<FontAwesomeIcon icon={faFileLines} />}
                        color={statesDocuments[row.state]?.color}
                    />
                </div>
            )
        },
        {
            name: t('views.documents.documentsTable.documentName'),
            sortable: 'document_type_name',
            width:'200px',
            wrap: true,
            cell: row => {
                const Tag = row?.state === 'LOADED' ? Link : 'div'
                return (
                    <div className={`text-truncate ${row?.state === 'LOADED' && 'seeDoc'}`}>
                        <div>
                            <Tag
                                to={`document-preview`}
                                className="d-block fw-bold"
                                onClick={() =>
                                    dispatch(
                                        setUrlPdf({
                                            location:
                                                row?.document_media?.location,
                                            name: row?.document_media?.name
                                        })
                                    )
                                }
                            >
                                {documentTypesWithName.includes(
                                    row?.document_type_name
                                ) ? (
                                    <>
                                        <JpText
                                            type="span-table-text"
                                            id={`doc-${row?.id}`}
                                        >
                                            {row?.name ||
                                                row.document_type_name}
                                        </JpText>
                                        <UncontrolledTooltip
                                            target={`doc-${row?.id}`}
                                        >
                                            {row.document_type_name}
                                        </UncontrolledTooltip>
                                    </>
                                ) : (
                                    <>
                                        <JpText
                                            type="span-table-text"
                                            id={`doc-${row?.id}`}
                                        >
                                            {row.document_type_name}
                                        </JpText>
                                        <UncontrolledTooltip
                                            target={`doc-${row?.id}`}
                                        >
                                            {row.document_type_name}
                                        </UncontrolledTooltip>
                                    </>
                                )}
                            </Tag>
                        </div>
                    </div>
                )
            }
        },
        {
            name: t('views.documents.documentsTable.date'),
            minWidth: '50px',
            width:'120px',
            sortable: 'created_at',
            cell: row => (
                <div className="d-flex align-items-center justify-content-center">
                    <JpText
                        type="span-table-date"
                        children={dateLocalizer.getIntlLongDate(row.created_at)}
                    />
                </div>
            )
        },
        {
            name: t('views.documents.documentsTable.documentState'),
            minWidth: '50px',
            width:'100px',
            sortable: 'state',
            cell: row => (
                <div className="d-flex align-items-center">
                    <div className="text-truncate ">
                        <span
                            className={`d-block fw-bold text-truncate text-${
                                statesDocuments[row?.state]?.color
                            }`}
                        >
                            {row?.state === null ? '-' : t(`${row?.state}`)}
                        </span>
                    </div>
                </div>
            )
        },
        {
            name: (
                <JpTableTitleName
                    customClass="me-1"
                    text={t('views.documents.contractsTable.actions')}
                />
            ),
            right: true,
            allowOverflow: true,
            cell: row => {
                let formWMedia = row?.document_metadata?.find(
                    i => i.metadata === 'FORM_MEDIA_ID'
                )
                let formWInstruction = row?.document_metadata?.find(
                    i => i.metadata === 'INSTRUCTION_MEDIA_ID'
                )
                return (
                    <>
                        {row?.document_media?.location &&
                            !(formWMedia && formWInstruction) && (
                                <>
                                    <JpTableAction
                                        icon={faFileArrowDown}
                                        id={`download-${row.user_id}`}
                                        tooltipText={t(
                                            'views.documents.documentsTable.downloadButton'
                                        )}
                                        onClick={() => {
                                            /*  SweetModal(
                                            'success',
                                            t('Success'),
                                            '',
                                            t('Ok')
                                        ) */
                                            downloadFile(
                                                row.document_media.location,
                                                row.document_media.name
                                            )
                                        }}
                                    />
                                </>
                            )}

                        {(row?.can_deleted && hasWritePermission) && (
                            <JpTableAction
                                icon={faTrashCan}
                                id={`delete-${row.user_id}`}
                                tooltipText={t(
                                    'views.documents.documentsTable.delete'
                                )}
                                onClick={() => {
                                    del({
                                        contractor_id: id,
                                        document_id: row?.id
                                    })
                                }}
                            />
                        )}
                        {(formWMedia && formWInstruction) !== undefined && (
                            <>
                                <JpTableAction
                                    icon={faFileLines}
                                    id={`download-instruction-${row.id}`}
                                    tooltipText={t(
                                        'views.documents.documentsTable.downloadInstructions'
                                    )}
                                    onClick={() =>
                                        downloadFile(
                                            formWInstruction?.value,
                                            `${row?.document_type_name} instructions`
                                        )
                                    }
                                />
                                <JpTableAction
                                    icon={faFileArrowDown}
                                    id={`download-${row.id}`}
                                    tooltipText={t(
                                        'views.documents.documentsTable.downloadTemplate'
                                    )}
                                    onClick={() =>
                                        downloadFile(
                                            row?.state === 'PENDING'
                                                ? formWMedia?.value
                                                : row?.document_media?.location,
                                            row?.document_type_name
                                        )
                                    }
                                />
                            </>
                        )}
                    </>
                )
            }
        }
    ]

    const onSubmit = data => {
        if (uid != '') {
            postDocument({
                data: {
                    document_type_id: uid,
                    file: replaceFile[0],
                    name: data?.name
                },
                id
            })
            setUid('')
            setReplaceFile([])
        } else {
            postDocument({
                data: {
                    document_type_id: data?.document_type_id?.value,
                    file: files[0],
                    name: data?.name
                },
                id
            })
        }
    }

    const handlePageChange = ({ page, limit, query, sort }) => {
        setCurrentPage(page)
        setLimit(limit)
        setQueries(query)
        if (sort) setSortBy(sort)
    }

    const handlePageChangeDoc = ({ page, limit, query, sort }) => {
        setCurrentPageDoc(page)
        setLimitDoc(limit)
        setQueriesDoc(query)
        if (sort) setSortByDoc(sort)
    }

    const onClose = () => {
        reset()
        setFiles([])
        setIsOpen(false)
    }

    if (postDocumentIsLoading) return <SpinnerLoader />
    return (
        <Row className=" mb-2">
            <JpFileModal
                isOpen={isOpen}
                headerContent={<h4>{t('Document')}</h4>}
                bodyContent={
                    <form className="mb-1">
                        <JpAsyncSelectController
                            name="document_type_id"
                            errors={errors}
                            control={control}
                            optionsAsyncSelect={{
                                defaultOptions: documentsType?.data.map(
                                    item => ({
                                        value: item.id,
                                        label: item.name,
                                        name: item.name
                                    })
                                )
                            }}
                        />

                        {requiredName() ? (
                            <div className="">
                                <JpLabel
                                    className="mt-1"
                                    labelOptions={{ for: 'name' }}
                                >{`${t('Name')}`}</JpLabel>
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            required
                                            id="name"
                                            maxLength="30"
                                            invalid={errors.name && true}
                                            {...field}
                                        />
                                    )}
                                />
                                <FormFeedback invalid>
                                    {t(`errors.${errors?.name?.message}`)}
                                </FormFeedback>
                            </div>
                        ) : (
                            ''
                        )}
                    </form>
                }
                onSubmit={handleSubmit(onSubmit)}
                toggle={onClose}
                files={files}
                setFiles={setFiles}
                fileInvalid={fileInvalid}
                setFileInvalid={setFileInvalid}
            />

            <JpFileModal
                isOpen={isReplace}
                headerContent={
                    <h4 className="m-1">{t('modals.replaceDocument')}</h4>
                }
                bodyContent={<></>}
                bodyPosition="bottom"
                onSubmit={handleSubmit(onSubmit)}
                toggle={() => setReplaceOpen(false)}
                files={replaceFile}
                setFiles={setReplaceFile}
                fileInvalid={fileInvalid}
                setFileInvalid={setFileInvalid}
            />
            <Col md="12" lg="6" className="mb-1 mb-lg-0">
                <JpTableV1
                    tableClassName="h-100"
                    cols={tabColsContracts}
                    fetcher={handlePageChange}
                    data={contractsProfile?.data}
                    total={contractsProfile?.count}
                    loading={contractsProfileIsLoading}
                    statusChange={shouldUpdate}
                    title={t('views.documents.contractsTable.title')}
                />
            </Col>
            <Col md="12" lg="6">
                <JpTableV1
                    tableClassName="h-100"
                    cols={tabColsDocuments}
                    fetcher={handlePageChangeDoc}
                    data={documentProfile?.data}
                    total={documentProfile?.count}
                    loading={documentIsLoading}
                    statusChange={shouldUpdate}
                    title={t('views.documents.documentsTable.title')}
                    actions={
                        hasWritePermission ? <div className="d-flex justify-content-end me-2">
                            <JpButton
                                id="add"
                                type="add"
                                tooltip
                                options={{
                                    onClick: () => {
                                        setIsOpen(true)
                                    },
                                    textLess: true,
                                    iconPosition: 'center'
                                }}
                            />
                        </div> : <></>
                    }
                />
            </Col>
        </Row>
    )
}

export default DocumentsTab
