import { useEffect, useState } from 'react'
import { Row, Col, Button } from 'reactstrap'
import { JpButtonGroup } from '@Intelli/utilities'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import ProductivityDetailsMain from './productivityDetails/DetailsMain'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AppsDetail from './productivityDetails/AppsDetail'
import ScreenshotsDetail from './productivityDetails/ScreenshotsDetail'
import TimelineDetails from './productivityDetails/TimelineDetails'
import { useDispatch } from 'react-redux'
import { addBreadcrumbs } from '@src/redux/breadcrumbs'
import AdminAPI from '@src/services/API'
import { useQuery } from '@tanstack/react-query'
import { parseName } from '@src/utility/Utils'
import usePermissionsProductivity from '@src/hooks/usePermissionsProductivity'

const ProductivityDetails = () => {
    const permissionsProductivity = usePermissionsProductivity()

    const API = new AdminAPI()
    const { t } = useTranslation()
    const [currentPage, setCurrentPage] = useState(
        permissionsProductivity.searchFirstModuleActive
    )

    const dispatch = useDispatch()
    const { id } = useParams()

    const {
        data: dataProfile,
        status,
        refetch
    } = useQuery(['profile', id], () => API.getContractor(id), {
        onSuccess: data => {
            dispatch(
                addBreadcrumbs({
                    id,
                    value: parseName(
                        data.user.first_name || data.email,
                        data.user.last_name
                    )
                })
            )
        }
    })

    const PageBody = () => {
        if (currentPage === 1) {
            return <ProductivityDetailsMain contractorId={id} />
        } else if (currentPage === 2) {
            return <AppsDetail />
        } else if (currentPage === 3) {
            return <TimelineDetails />
        } else if (currentPage === 4) {
            return (
                <ScreenshotsDetail
                    permissionWriteScreenshots={
                        permissionsProductivity.permissionWriteScreenshots
                    }
                />
            )
        }
    }

    const [params, setSearchParams] = useSearchParams()

    useEffect(() => {
        if (params.get('page')) {
            setCurrentPage(parseInt(params.get('page')))
        }
    }, [params])


    const onTabChange = index => {
        setSearchParams({
            page: index
        })
    }

    return (
        <div>
            <Row>
                <Col>
                    <JpButtonGroup className="mb-1">
                        {permissionsProductivity.permissionDashboard && (
                            <Button
                                color="info"
                                onClick={() => onTabChange(1)}
                                active={currentPage === 1}
                                outline={currentPage !== 1}
                            >
                                <FontAwesomeIcon
                                    className="me-50"
                                    icon="fa-solid fa-alarm-clock"
                                />

                                {t('Productivity')}
                            </Button>
                        )}

                        {permissionsProductivity.permissionApps && (
                            <Button
                                color="info"
                                onClick={() => onTabChange(2)}
                                active={currentPage === 2}
                                outline={currentPage !== 2}
                            >
                                <FontAwesomeIcon
                                    className="me-50"
                                    icon="fa-solid fa-browser"
                                />
                                {t('views.productivity.apps')}
                            </Button>
                        )}
                        {permissionsProductivity.permissionTimeline && (
                            <Button
                                color="info"
                                onClick={() => onTabChange(3)}
                                active={currentPage === 3}
                                outline={currentPage !== 3}
                            >
                                <FontAwesomeIcon
                                    className="me-50"
                                    icon="fa-solid fa-timeline"
                                />
                                {t('views.productivity.timeline')}
                            </Button>
                        )}
                        {permissionsProductivity.permissionScreenshots && (
                            <Button
                                color="info"
                                onClick={() => onTabChange(4)}
                                active={currentPage === 4}
                                outline={currentPage !== 4}
                            >
                                <FontAwesomeIcon
                                    className="me-50"
                                    icon="fa-solid fa-image"
                                />
                                {t('views.productivity.screenshots')}
                            </Button>
                        )}
                    </JpButtonGroup>
                </Col>
            </Row>

            <PageBody />
        </div>
    )
}

export default ProductivityDetails
