// ** React Imports
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Third Party Components
import { User, Power, MessageCircle, ArrowLeft } from 'react-feather'

// ** Reactstrap Imports
import {
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    Button
} from 'reactstrap'

import { SpinnerLoader, user$ } from '@Intelli/utilities'
import { useMutation, useQuery } from '@tanstack/react-query'
import AdminAPI from '@src/services/API'
import { useTranslation } from 'react-i18next'
import { parseName } from '@src/utility/Utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserTie, faWallet } from '@fortawesome/pro-solid-svg-icons'
import { setBackofficeUser } from '@src/redux/user'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

const CustomUserDropdown = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const API = new AdminAPI()

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    const token = localStorage.getItem('token') || searchParams.get('token')

    const { userAuth, userAdmin } = useSelector(state => state.backofficeUser)

    const { mutate: logout } = useMutation(() => API.logout(), {
        onSuccess: () => {
            localStorage.clear()
            navigate('/')
        }
    })

    const logoutHandler = e => {
        e.preventDefault()
        logout()
    }

    const Dropdown = () => {
        const { mutate: handleWorkspaceChange } = useMutation(
            workspaceId => API.changeContractorWorkspace(workspaceId),
            {
                onSuccess: data => {
                    localStorage.setItem(
                        'token',
                        JSON.stringify(data.token.token)
                    )
                    window.location = '/wallet'
                }
            }
        )
        if (!userAuth) return null
        return (
            <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
                <DropdownToggle
                    href="/"
                    tag="a"
                    className="nav-link dropdown-user-link"
                    onClick={e => e.preventDefault()}
                >
                    <div className="user-nav d-sm-flex d-none">
                        <span className="user-name fw-bold">
                            {parseName(
                                userAuth.user.first_name,
                                userAuth.user.last_name
                            )}
                        </span>
                        <span className="user-status">
                            {t(`roles.${userAdmin.role}`)}
                        </span>
                    </div>
                    {userAuth.photo?.thumbnail ? (
                        <Avatar
                            img={userAuth.photo?.photo}
                            imgHeight="40"
                            imgWidth="40"
                            imgClassName="avatar-cover"
                        />
                    ) : (
                        <Avatar
                            content={parseName(
                                userAuth.user.first_name,
                                userAuth.user.last_name
                            )}
                            initials
                            color="info"
                            imgHeight="40"
                            imgWidth="40"
                        />
                    )}
                </DropdownToggle>
                <DropdownMenu end>
                    <DropdownItem tag={Link} to={`/admin/account-settings`}>
                        <FontAwesomeIcon icon="user" className="me-75" />
                        <span className="align-middle">{t('profile')}</span>
                    </DropdownItem>
                    {/* <DropdownItem divider /> */}
                    {/* <DropdownItem tag={Link} to="/admin">
            <MessageCircle size={14} className="me-75" />
            Support
          </DropdownItem> */}
                    {user$.value.companiesCount > 1 && (
                        <>
                            <DropdownItem tag={Link} to="/lobby?auto=false">
                                <FontAwesomeIcon
                                    icon="buildings"
                                    className="me-75"
                                />
                                {t('companies')}
                            </DropdownItem>
                        </>
                    )}
                    {(user$.value.company || user$.value.backoffice) && (
                        <>
                            <DropdownItem divider />
                            {/* <DropdownItem text header>
                                <span className="align-middle">
                                    {t('change_to')}
                                </span>
                            </DropdownItem> */}
                        </>
                    )}
                    <DropdownItem
                        tag={Button}
                        className="rounded-0"
                        onClick={() => {
                            if (user$.value.company.contractor)
                                handleWorkspaceChange({
                                    contractor_id:
                                        user$.value.company.contractor
                                            .contractor_id
                                })
                            else window.location = '/wallet'
                        }}
                        color="link"
                        block
                    >
                        <FontAwesomeIcon icon={faWallet} className="me-75" />
                        {t('wallet')}
                    </DropdownItem>
                    {user$.value.backoffice && (
                        <DropdownItem
                            tag={Link}
                            to="/backoffice"
                            reloadDocument
                        >
                            <FontAwesomeIcon
                                icon={faUserTie}
                                className="me-75"
                            />
                            {t('backoffice')}
                        </DropdownItem>
                    )}
                    <DropdownItem divider />
                    {/* <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
                        <HelpCircle size={14} className="me-75" />
                        <span className="align-middle">FAQ</span>
                    </DropdownItem> */}
                    {/* <DropdownItem
                        tag={Link}
                        to="/"
                        onClick={e => {
                            e.preventDefault()
                            window.open(
                                'https://joobpay.odoo.com/knowledge/article/10',
                                '_blank'
                            )
                        }}
                    >
                        <FontAwesomeIcon
                            icon="circle-question"
                            size="lg"
                            id="support"
                            className="me-75"
                        />
                        <span className="align-middle">{t('FAQ')}</span>
                    </DropdownItem> */}
                    <DropdownItem
                        tag={Link}
                        to="/"
                        onClick={e => {
                            e.preventDefault()
                            window.open(
                                'https://joobpay.com/support/',
                                '_blank'
                            )
                        }}
                    >
                        <FontAwesomeIcon
                            icon="fa-solid fa-user-headset"
                            size="lg"
                            id="support"
                            className="me-75"
                        />
                        <span className="align-middle">{t('support')}</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem tag={Link} to="/" onClick={logoutHandler}>
                        <FontAwesomeIcon icon="power-off" className="me-75" />
                        <span className="align-middle">{t('logOut')}</span>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        )
    }
    if (token) {
        return <Dropdown />
    } else {
        return <Navigate to="/" />
    }
}

export default CustomUserDropdown
