import {
    faChartSimple,
    faListCheck,
    faAlarmClock,
    faTrashCan,
    faHourglassClock
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Button,
    FormFeedback,
    Input,
    ListGroup,
    ListGroupItem,
    UncontrolledTooltip
} from 'reactstrap'
import { SpinnerLoader, JpAvatar, SweetModal } from '@Intelli/utilities'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const typeIcon = {
    CLASSIC: faChartSimple,
    TIME: faAlarmClock,
    TASKS: faListCheck,
    undefined: faHourglassClock
}

const ContractTemplatesList = ({
    templates = [],
    selectedTemplate,
    setSelectedTemplate,
    setTemporalTemplate,
    setData
}) => {
    const { t } = useTranslation()
    const formCtx = useFormContext()

    if (!formCtx) return <SpinnerLoader />

    const {
        control,
        setValue,
        clearErrors,
        formState: { errors }
    } = formCtx

    return (
        <>
            <ListGroup
                flush
                action
                className={`scroll-list ${
                    !!errors.contract_template_id
                        ? 'border rounded is-invalid'
                        : ''
                }`}
            >
                {templates.map((template, index) => (
                    <ListGroupItem
                        className={`d-flex p-1 cursor-pointer align-items-center hover-dark rounded border-0 ${
                            selectedTemplate?.id ===
                            template?.contract_template_id
                                ? `border-${template?.color}`
                                : ''
                        }`}
                        key={index}
                        onClick={() => {
                            setValue(
                                'contract_template_id',
                                template?.contract_template_id
                            )
                            setSelectedTemplate({
                                index,
                                id: template?.contract_template_id
                            })
                            clearErrors('contract_template_id')
                        }}
                    >
                        <JpAvatar
                            color={`${
                                selectedTemplate?.id ===
                                template?.contract_template_id
                                    ? ''
                                    : 'light-'
                            }${template?.color || 'cyan'}`}
                            icon={
                                <FontAwesomeIcon
                                    icon={typeIcon[template?.contract_type]}
                                />
                            }
                            className="me-75"
                        />
                        <p
                            className={`m-0 ${
                                selectedTemplate?.id ===
                                template?.contract_template_id
                                    ? `text-${template?.color || 'primary'}`
                                    : ''
                            }`}
                        >
                            {template.name}
                        </p>
                        {template.erasable && (
                            <>
                                <UncontrolledTooltip
                                    placement="top"
                                    target={`delete-${template.contract_template_id}`}
                                >
                                    {t('modals.deleteTemplate')}
                                </UncontrolledTooltip>
                                <Button
                                    type="button"
                                    color="link"
                                    onClick={() => {
                                        SweetModal(
                                            'warning',
                                            t('modals.deleteTemplate'),
                                            t('modals.deleteTemplateMsg'),
                                            t('Ok'),
                                            ({ isConfirmed }) => {
                                                if (isConfirmed) {
                                                    localStorage.removeItem(
                                                        'temporalTemplate'
                                                    )
                                                    if (
                                                        selectedTemplate?.id ===
                                                        template?.contract_template_id
                                                    ) {
                                                        setSelectedTemplate(
                                                            null
                                                        )
                                                        setValue(
                                                            'contract_template_id',
                                                            ''
                                                        )
                                                    }
                                                    setTemporalTemplate(null)
                                                    setData(
                                                        templates.filter(
                                                            item =>
                                                                item.contract_template_id !==
                                                                template.contract_template_id
                                                        )
                                                    )

                                                    SweetModal(
                                                        'success',
                                                        t('Success'),
                                                        t(
                                                            'modals.deletedTemplateSuccess'
                                                        ),
                                                        t('Ok')
                                                    )
                                                }
                                            },
                                            {
                                                showCancelButton: true,
                                                cancelButton: t('Cancel')
                                            }
                                        )
                                    }}
                                    className="ms-auto p-0"
                                    id={`delete-${template.contract_template_id}`}
                                >
                                    <FontAwesomeIcon
                                        icon={faTrashCan}
                                        className="table-action"
                                    />
                                </Button>
                            </>
                        )}
                    </ListGroupItem>
                ))}
            </ListGroup>
            <Controller
                name="contract_template_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                    <Input
                        type="hidden"
                        name="contract_template_id"
                        invalid={errors?.contract_template_id && true}
                        {...field}
                    />
                )}
            />
            <FormFeedback invalid className="mt-1">
                {t(
                    `form.contractors.${errors?.contract_template_id?.message}`,
                    { ns: 'errors' }
                )}
            </FormFeedback>
        </>
    )
}

export default ContractTemplatesList
