import { JpAvatar, JpBombLight } from '@Intelli/utilities'
import { useEffect, useState } from 'react'
import useSocket from '@src/hooks/useSocket'
import { Badge, UncontrolledTooltip } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { socket } from '@src/services/SocketConfig'

/* hay que pasarle la prop key={row.contractor_id} //Esta key se necesita para asociar el contractor_id al bombillo

@Example <ContractorAvatar
                        key={row.contractor_id} //Esta key se necesita para asociar el contractor_id al bombillo
                        content={{
                            photo: row?.photo,
                            name: row?.first_name,
                            bombLight: row?.realtime?.state,
                            contractor_id: row.contractor_id
                        }}
                    />

*/

const ContractorAvatar = ({ content, className }) => {
    const { t } = useTranslation()
    const [bombLight, setBombLight] = useState(content.bombLight)

    useEffect(() => {
        setBombLight(content.bombLight)
    }, [content])

    useSocket(
        //Siempre tiene que estar suscripto
        {
            [`/contractors/${content.contractor_id}/state`]: {
                timeUnsubscribe: 500,
                fn: value => {
                    setBombLight(value.payload.contractor.state)
                }
            }
        },
        []
    )

    return (
        <div className={`${className ? className : ''}`}>
            {content?.photo ? (
                <div className="d-flex align-items-end">
                    <JpAvatar
                        color={`light-info`}
                        img={content?.photo}
                        imgClassName="avatar-cover cursor-default"
                    />
                    {bombLight ? (
                        <JpBombLight state={bombLight} />
                    ) : (
                        <JpBombLight state={'DISCONNECTED'} />
                    )}
                </div>
            ) : (
                <div className="d-flex align-items-end">
                    <JpAvatar
                        color={`light-info`}
                        content={content.name && `${content.name}`}
                        initials
                        imgClassName="avatar-cover cursor-default"
                    />
                    {bombLight ? (
                        <JpBombLight state={bombLight} />
                    ) : (
                        <JpBombLight state={'DISCONNECTED'} />
                    )}
                </div>
            )}
            {content.withbadget && (
                <>
                    <JpAvatar
                        id={`added-${content.contractor_id}`}
                        /* style={{
                            borderRadius: '50px',
                            height: '20px',
                            width: '20px'
                        }} */
                        size="sm"
                        className="ms-3 mb-2 position-absolute text-center"
                        color={
                            content.isManual ? 'light-warning' : 'light-info'
                        }
                        content={content.isManual ? 'M' : 'A'}
                    />

                    <UncontrolledTooltip
                        target={`added-${content.contractor_id}`}
                    >
                        {t(
                            `views.payments.unpaid.${
                                content.isManual
                                    ? 'manuallyAdded'
                                    : 'proposalAdded'
                            }`
                        )}
                    </UncontrolledTooltip>
                </>
            )}
        </div>
    )
}

export default ContractorAvatar
