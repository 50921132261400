import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { useQuery } from '@tanstack/react-query'
import {
    SpinnerLoader,
    JpStatsIconCard,
    JpPieGraph,
    JpGraphCard,
    parseMinutesToHours,
    JpAvatar,
    formatDatesUtc,
    JpText,
    JpButton
} from '@Intelli/utilities'
import { useTranslation } from 'react-i18next'
import AdminAPI from '@src/services/API'
import useSocket from '@src/hooks/useSocket'
import { productivityStatesIcons } from '@src/constants/icons/productivityStatesIcons'
import moment from 'moment'
import disabledRefetchSocket from '@src/utility/disabledRefetchSocket'

const DetailsChartsCards = ({ start_date, end_date }) => {

    const { id } = useParams()
    const { t } = useTranslation()
    const API = new AdminAPI()
    const {
        data: getContractorProductivityChartsData,
        isLoading: getContractorProductivityChartsIsLoading,
        refetch: contractorProductivityRefecth
    } = useQuery(['getContractorProductivityChartsCards', id], () =>
        API.getContractorProductivityCharts(id, {
            date__between: `${formatDatesUtc.getDateStringtoUTC(
                start_date
            )},${formatDatesUtc.getDateStringtoUTC(
                moment(end_date).add(1, 'days').format('YYYY-MM-DD')
            )}`
        })
    )

    useEffect(() => {
        contractorProductivityRefecth()
    }, [start_date, end_date])

    useSocket(
        {
            [`/productivity/contractors/${id}/charts/contractor_times`]: {
                fn: value => {
                    disabledRefetchSocket(end_date) &&
                        contractorProductivityRefecth()
                }
            }
        },
        [id]
    )

    return (
        <Row className="mb-1">
            <Col lg="3" sm="6" xs="12" className="py-1 py-lg-0">
                <JpStatsIconCard
                    loading={getContractorProductivityChartsIsLoading}
                    title={t('views.productivity.productive')}
                    stat={`${parseMinutesToHours(
                        getContractorProductivityChartsData?.productive?.time
                    )} (${Math.round(
                        getContractorProductivityChartsData?.productive
                            ?.percent_work_time
                    )}%)`}
                    statSize="sm"
                    icon={
                        <JpAvatar
                            icon={
                                <i
                                    class={
                                        productivityStatesIcons.PRODUCTIVE.icon
                                    }
                                ></i>
                            }
                            color={productivityStatesIcons.PRODUCTIVE.color}
                        />
                    }
                    iconBgColor={productivityStatesIcons.PRODUCTIVE.color}
                    iconSize="xl"
                    iconDirection="left"
                    subtitle={t('views.time.graphs.lastPeriod')}
                    subtitlePercentage={
                        getContractorProductivityChartsData?.productive
                            ?.percent_last
                    }
                />
            </Col>
            <Col lg="3" sm="6" xs="12" className="py-1 py-lg-0">
                <JpStatsIconCard
                    loading={getContractorProductivityChartsIsLoading}
                    title={t('views.productivity.neutral')}
                    stat={`${parseMinutesToHours(
                        getContractorProductivityChartsData?.neutral?.time
                    )} (${Math.round(
                        getContractorProductivityChartsData?.neutral
                            ?.percent_work_time
                    )}%)`}
                    statSize="sm"
                    icon={
                        <JpAvatar
                            icon={
                                <i
                                    class={productivityStatesIcons.NEUTRAL.icon}
                                ></i>
                            }
                            color={productivityStatesIcons.NEUTRAL.color}
                        />
                    }
                    iconBgColor={productivityStatesIcons.NEUTRAL.color}
                    iconSize="xl"
                    iconDirection="left"
                    subtitle={t('views.time.graphs.lastPeriod')}
                    subtitlePercentage={
                        getContractorProductivityChartsData?.neutral
                            ?.percent_last
                    }
                />
            </Col>
            <Col lg="3" sm="6" xs="12" className="py-1 py-lg-0">
                <JpStatsIconCard
                    swapBgColor={true}
                    loading={getContractorProductivityChartsIsLoading}
                    title={t('views.productivity.unproductive')}
                    stat={`${parseMinutesToHours(
                        getContractorProductivityChartsData?.unproductive?.time
                    )} (${Math.round(
                        getContractorProductivityChartsData?.unproductive
                            ?.percent_work_time
                    )}%)`}
                    statSize="sm"
                    icon={
                        <JpAvatar
                            icon={
                                <i
                                    class={
                                        productivityStatesIcons.UNPRODUCTIVE
                                            .icon
                                    }
                                ></i>
                            }
                            color={productivityStatesIcons.UNPRODUCTIVE.color}
                        />
                    }
                    iconBgColor={productivityStatesIcons.UNPRODUCTIVE.color}
                    iconSize="xl"
                    iconDirection="left"
                    subtitle={t('views.time.graphs.lastPeriod')}
                    subtitlePercentage={
                        getContractorProductivityChartsData?.unproductive
                            ?.percent_last
                    }
                />
            </Col>
            <Col lg="3" sm="6" xs="12" className="py-1 py-lg-0">
                <JpStatsIconCard
                    swapBgColor={true}
                    loading={getContractorProductivityChartsIsLoading}
                    title={t('views.productivity.inactivity')}
                    stat={`${parseMinutesToHours(
                        getContractorProductivityChartsData?.afk?.time
                    )}`}
                    statSize="sm"
                    icon={
                        <JpAvatar
                            icon={<i class="fa-solid fa-alarm-snooze"></i>}
                            color={'yellow'}
                        />
                    }
                    iconBgColor="light-info"
                    iconSize="xl"
                    iconDirection="left"
                    subtitle={t('views.time.graphs.lastPeriod')}
                    subtitlePercentage={
                        getContractorProductivityChartsData?.afk?.percent_last
                    }
                />
            </Col>
        </Row>
    )
}

export default DetailsChartsCards
