import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Briefcase, Home, User, TrendingUp, Trello, CreditCard, DollarSign, BarChart2, Clock, Settings } from "react-feather"

const main_route = "/admin"

export default [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <FontAwesomeIcon icon="fa-solid fa-house" />,
    navLink: `${main_route}/home`
  },
  {
    id: "recruitment",
    title: "Recruitment",
    icon: <FontAwesomeIcon icon="fa-solid fa-briefcase" />,
    navLink: `${main_route}/recruitment`
  },
  {
    id: "contractor",
    title: "Contractors",
    icon: <FontAwesomeIcon icon="fa-solid fa-user" />,
    navLink: `${main_route}/contractors`
  },
  {
    id: "productivity",
    title: "Productivity",
    icon: <FontAwesomeIcon icon="fa-solid fa-chart-line-up" />,
    navLink: `${main_route}/productivity`
  },
  {
    id: "time",
    title: "Time",
    icon: <FontAwesomeIcon icon="fa-solid fa-clock" />,
    navLink: `${main_route}/time`
  },
  {
    id: "projects",
    title: "Projects",
    icon: <FontAwesomeIcon icon="fa-solid fa-square-kanban" />,
    navLink: `${main_route}/projects`
  },
  {
    id: "payroll",
    title: "Payroll",
    icon: <FontAwesomeIcon icon="fa-solid fa-money-check-dollar" />,
    navLink: `${main_route}/payroll`
  },
  {
    id: "invoices",
    title: "Invoices",
    icon: <FontAwesomeIcon icon="fa-solid fa-file-invoice-dollar" />,
    navLink: `${main_route}/invoices`
  },
  {
    id: "reports",
    title: "Reports",
    icon: <FontAwesomeIcon icon="fa-solid fa-chart-simple" />,
    navLink: `${main_route}/reports`
  },
  {
    id: "settings",
    title: "Settings",
    icon: <FontAwesomeIcon icon="fa-solid fa-gear" />,
    navLink: `${main_route}/settings`
  }
]
