import { SpinnerLoader, JpText, JpIcon } from '@Intelli/utilities'
import { faFlagCheckered } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const ContractLoaderModal = () => {
    const { t } = useTranslation()

    const contractText = {
        1: t('modals.createContract'),
        2: t('modals.setDataContract'),
        3: t('modals.verifySignatories'),
        4: t('modals.almostReady')
    }

    const [contractMsg, setContractMsg] = useState(contractText[1])
    const [indexMsg, setIndexMsg] = useState(1)

    const contractMgs = () => {
        setContractMsg(contractText[indexMsg])
        if (indexMsg < 4) {
            setIndexMsg(indexMsg + 1)
        }
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            contractMgs()
        }, 1200)
        return () => {
            clearInterval(timeout)
        }
    }, [indexMsg, contractMsg])

    return (
        <div className="d-flex flex-column justify-content-center align-items-center my-2">
            <div>
                <h4 className="mb-5">{t('modals.contractChangeState')}</h4>
            </div>

            <div>
                <SpinnerLoader />

                <JpText
                    type="p-text"
                    className="mt-5 me-1 text-center"
                    children={`${contractMsg}`}
                />
            </div>
        </div>
    )
}

export default ContractLoaderModal
