import { createSlice } from '@reduxjs/toolkit'

export const breadcrumbs = createSlice({
    name: 'breadcrumbs',
    initialState: {
        params: {}
    },
    reducers: {
        addBreadcrumbs: (state, action) => {
            state[action.payload.id] = action.payload.value
        },
        addParamsValues: (state, action) => {
            state['params'] = { ...action.payload }
        }
    }
})

export const { addBreadcrumbs, addParamsValues } = breadcrumbs.actions

export default breadcrumbs.reducer
