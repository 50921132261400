import {
    JpTableV1,
    JpCurrencyNumber,
    dateLocalizer,
    JpText,
    JpDatePicker,
    JpAvatar,
    JpTableAction,
    JpTableActionsCol,
    JpTableTitleName,
    ContractorNameInfo,
    SweetModal,
    JpButton,
    TIME_PERMISSIONS_STATUS
} from '@Intelli/utilities'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import moment from 'moment-timezone'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import AdminAPI from '@src/services/API'
import { faCheck, faTrashCan } from '@fortawesome/pro-solid-svg-icons'
import useSocket from '@src/hooks/useSocket'
import ModifyPermissionModal from '@src/components/time/permissions/ModifyPermissionModal'
import usePermissionTimePermissions from '@src/hooks/usePermissionTimePermissions'
import PermissionsGraphs from './PermissionsGraphs'
import { addBreadcrumbs } from '@src/redux/breadcrumbs'
import { useDispatch } from 'react-redux'
import { parseName } from '@src/utility/Utils'

const TabActions = ({
    start_date,
    setStartDate,
    end_date,
    setEndDate,
    setCurrentPage,
    onClick,
    hasWriteTimePermissions
}) => {
    return (
        <Row className="align-items-center justify-content-between">
            <Col
                sm="5"
                md="4"
                lg="3"
                xl="3"
                className="d-none d-lg-flex table-filters"
            >
                <JpDatePicker
                    id="contractStartDate"
                    value={start_date}
                    setPicker={date => {
                        setCurrentPage(0)
                        setStartDate(date.format('YYYY-MM-DD'))
                    }}
                    maxDate={end_date}
                    className={'enabled-joobpay'}
                />

                <JpDatePicker
                    id="contractEndDate"
                    value={end_date}
                    minDate={moment(start_date).format('YYYY-MM-DD')}
                    /* maxDate={moment().format('YYYY-MM-DD')} */
                    setPicker={date => {
                        setCurrentPage(0)
                        setEndDate(date.format('YYYY-MM-DD'))
                    }}
                    className={'enabled-joobpay ms-1'}
                />
            </Col>
            <Col lg="5" xl="1" className="d-flex justify-content-end me-1">
                {hasWriteTimePermissions && (
                    <JpButton
                        id="add"
                        type="add"
                        tooltip
                        options={{
                            onClick: onClick,
                            textLess: true,
                            iconPosition: 'center'
                        }}
                    />
                )}
            </Col>
        </Row>
    )
}

const states = {
    APPROVED: {
        title: 'approvePermission',
        msg: 'approvePermissionTxt',
        success: 'approvePermissionSuccess'
    },
    PENDING: {
        title: 'disapprovePermission',
        msg: 'disapprovePermissionTxt',
        success: 'disapprovePermissionSuccess'
    },
    REJECTED: {
        title: 'rejectPermission',
        msg: 'rejectPermissionTxt',
        success: 'rejectPermissionSuccess'
    }
}

const PermissionsDetails = () => {
    const [start_date, setStartDate] = useState(
        moment().subtract(3, 'months').format('YYYY-MM-DD')
    )
    const [end_date, setEndDate] = useState(
        moment().add(3, 'months').format('YYYY-MM-DD')
    )
    const [currentPage, setCurrentPage] = useState(0)
    const [limit, setLimit] = useState(10)
    const [queries, setQueries] = useState('')
    const [sortBy, setSortBy] = useState('created_at__desc')
    const [permissionModal, setPermissionModal] = useState(false)
    const [editData, setEditData] = useState(null)
    const [approvalModal, setApprovalModal] = useState(false)
    const [detailModal, setDetailModal] = useState(false)

    const { hasWriteTimePermissions } = usePermissionTimePermissions()
    const API = new AdminAPI()
    const { t } = useTranslation()
    const { id } = useParams()
    const dispatch = useDispatch()
    const queryClient = useQueryClient()

    const {
        data: dataProfile,
        status,
        refetch
    } = useQuery(['profile', id], () => API.getContractor(id), {
        enabled: !window.location.href.includes('contractors/profile'),
        onSuccess: data => {
            dispatch(
                addBreadcrumbs({
                    id,
                    value: parseName(
                        data.user.first_name || data.email,
                        data.user.last_name
                    )
                })
            )
        }
    })

    const {
        data: getContractorPermissions,
        isLoading: getContractorPermissionsLoading,
        refetch: getContractorPermissionsRefetch
    } = useQuery(
        [
            'ContractorPermissions',
            currentPage,
            limit,
            start_date,
            end_date,
            queries,
            sortBy
        ],
        () =>
            API.getContractorPermissions(id, {
                offset: currentPage,
                limit,
                start_date__lte: end_date,
                end_date__gte: start_date,
                contractor_id: id,
                query: queries,
                order: sortBy
            })
    )

    useSocket(
        {
            [`/time/permissions_contractors/${id}/details`]: {
                fn: value => {
                    value.payload.permissions.some(data => {
                        if (['created', 'removed'].includes(data?.action)) {
                            refetch()
                            return true
                        } else if ('updated' === data?.action) {
                            const newItem = data?.data
                            queryClient.setQueryData(
                                [
                                    'ContractorPermissions',
                                    currentPage,
                                    limit,
                                    start_date,
                                    end_date,
                                    queries,
                                    sortBy
                                ],
                                old => {
                                    const newPermissions = old?.data?.map(
                                        item => {
                                            if (item?.id === newItem?.id) {
                                                return {
                                                    ...item,
                                                    ...newItem
                                                }
                                            }
                                            return item
                                        }
                                    )
                                    return {
                                        ...old,
                                        data: newPermissions
                                    }
                                }
                            )
                            return false
                        }
                    })
                }
            }
        },
        [
            start_date,
            end_date,
            id,
            currentPage,
            limit,
            start_date,
            end_date,
            queries,
            sortBy
        ]
    )

    const { mutate: deletePermission } = useMutation(
        id => API.deletePermission(id),
        {
            onSuccess: () => {
                SweetModal(
                    'success',
                    t('Success'),
                    t('views.permissions.deletePermissionSuccess'),
                    t('Ok')
                )
                getContractorPermissionsRefetch()
            }
        }
    )

    const { mutate: changeStatePermission } = useMutation(
        data => API.changeStatePermission(data),
        {
            onSuccess: (_, data) => {
                const msg = t(
                    `views.permissions.${states[data.data.state].success}`
                )
                SweetModal('success', t('Success'), msg, t('Ok'))
                getContractorPermissionsRefetch()
            }
        }
    )


    const changeState = data => {
        const title = t(`views.permissions.${states[data.data.state].title}`)
        const msg = t(`views.permissions.${states[data.data.state].msg}`)

        SweetModal(
            'warning',
            title,
            msg,
            t('Ok'),
            ({ isConfirmed }) => {
                if (isConfirmed) {
                    changeStatePermission(data)
                }
            },
            { showCancelButton: true, cancelButtonText: t('CANCEL') }
        )
    }

    const deletePermissionFunction = id => {
        SweetModal(
            'warning',
            t('views.permissions.deletePermissionTitle'),
            t('views.permissions.deletePermissionTxt'),
            t('Ok'),
            ({ isConfirmed }) => {
                if (isConfirmed) {
                    deletePermission(id)
                }
            },
            { showCancelButton: true, cancelButtonText: t('CANCEL') }
        )
    }

    const tabCols = [
        {
            name: `${t('views.productivity.date')}`,
            sortable: 'created_at',
            cell: row => {
                return (
                    <JpText
                        type="span-table-date"
                        children={
                            row?.created_at
                                ? dateLocalizer.getIntlLongDate(row?.created_at)
                                : '-'
                        }
                    />
                )
            }
        },
        {
            name: t('views.permissions.permission'),
            sortable: 'permission_name',
            width: '150px',
            cell: row => {
                const Tag = 'div'

                return (
                    <div
                        className="user-info text-primary cursor-pointer text-truncate"
                        onClick={() => {
                            setApprovalModal(false)
                            setDetailModal(true)
                            setEditData({
                                contractor_permission_id: row?.id
                            })
                            setPermissionModal(true)
                        }}
                    >
                        <ContractorNameInfo
                            Tag={Tag}
                            classNameContainer={'d-flex flex-column '}
                            largeName={
                                row?.permission_name
                                    ? row?.permission_name
                                    : '-'
                            }
                            contractor_id={row?.id}
                        />
                    </div>
                )
            }
        },
        {
            name: t('views.permissions.start'),
            sortable: 'start_date',
            cell: row => {
                return (
                    <JpText
                        type="span-table-date"
                        children={
                            row?.start_date
                                ? dateLocalizer.getIntlLongDate(row?.start_date)
                                : '-'
                        }
                    />
                )
            }
        },
        {
            name: t('views.permissions.end'),
            sortable: 'end_date',
            cell: row => {
                return (
                    <JpText
                        type="span-table-date"
                        children={
                            row?.end_date
                                ? dateLocalizer.getIntlLongDate(row?.end_date)
                                : '-'
                        }
                    />
                )
            }
        },
        {
            name: t('views.payments.unpaid.totalAmount'),
            sortable: 'amount',
            width: '110px',
            cell: row => {
                return row?.pay ? <JpCurrencyNumber value={row?.amount} /> : '-'
            }
        },
        {
            name: t('hours'),
            sortable: 'type',
            width: '100px',
            cell: row => {
                return <JpText type="span-table-text" children={row?.hours} />
            }
        },
        {
            name: t('Status'),
            sortable: 'state',
            width: '110px',
            cell: row => {
                const objState =
                    TIME_PERMISSIONS_STATUS[
                        Object.keys(TIME_PERMISSIONS_STATUS)?.find(item =>
                            row?.state?.includes(item)
                        )
                    ]
                return (
                    <>
                        {row?.state && (
                            <>
                                <JpAvatar
                                    id={`state-${row?.id}`}
                                    color={objState?.color}
                                    className="cursor-default"
                                    /* content={t(
                                        `views.permissions.status.${objState.i18n}`
                                    )} */
                                    icon={objState.icon}
                                />
                                <UncontrolledTooltip
                                    target={`state-${row?.id}`}
                                >
                                    {t(
                                        `views.permissions.status.${objState.i18n}`
                                    )}
                                </UncontrolledTooltip>
                            </>
                        )}
                    </>
                )
            }
        },
        {
            name: (
                <JpTableTitleName
                    customClass="me-1"
                    text={t('views.teams.actions')}
                />
            ),
            right: true,
            cell: (row, index) => {
                if (!hasWriteTimePermissions) {
                    return <></>
                }
                return (
                    <>
                        {row?.state && (
                            <>
                                {row?.state === 'PENDING' ? (
                                    <JpTableActionsCol
                                        children={
                                            <>
                                                <JpTableAction
                                                    icon={faCheck}
                                                    id={`approve-${index}`}
                                                    tooltipText={t(
                                                        'views.payments.detail.approve'
                                                    )}
                                                    onClick={() => {
                                                        changeState({
                                                            contractor_permission_id:
                                                                row?.id,
                                                            data: {
                                                                state: 'APPROVED'
                                                            }
                                                        })
                                                    }}
                                                />
                                            </>
                                        }
                                    />
                                ) : (
                                    [
                                        'APPROVED_PAY',
                                        'APPROVED_NOT_PAY',
                                        'APPROVED'
                                    ].includes(row?.state) && (
                                        <JpTableActionsCol
                                            children={
                                                <>
                                                    <JpTableAction
                                                        icon="fa fa-rotate-left"
                                                        id={`disapprove-${index}`}
                                                        tooltipText={t(
                                                            'views.payments.detail.disapprove'
                                                        )}
                                                        onClick={() => {
                                                            changeState({
                                                                contractor_permission_id:
                                                                    row?.id,
                                                                data: {
                                                                    state: 'PENDING'
                                                                }
                                                            })
                                                        }}
                                                        className="me-2"
                                                    />
                                                </>
                                            }
                                        />
                                    )
                                )}
                            </>
                        )}
                        {row?.state === 'PENDING' && (
                            <JpTableAction
                                icon="fa fa-xmark"
                                id={`reject-${row?.id}`}
                                tooltipText={t('REJECT')}
                                onClick={() => {
                                    changeState({
                                        contractor_permission_id: row?.id,
                                        data: {
                                            state: 'REJECTED'
                                        }
                                    })
                                }}
                            />
                        )}

                        {row?.state === 'PENDING' && (
                            <>
                                <JpTableAction
                                    icon="fa fa-pen-to-square"
                                    id={`edit-${row.id}`}
                                    tooltipText={t('EDIT')}
                                    onClick={() => {
                                        setDetailModal(false)
                                        setApprovalModal(false)
                                        setEditData({
                                            contractor_permission_id: row?.id
                                        })
                                        setPermissionModal(true)
                                    }}
                                />

                                <JpTableAction
                                    icon={faTrashCan}
                                    id={`delete-${row?.id}`}
                                    tooltipText={t(
                                        'views.documents.documentsTable.delete'
                                    )}
                                    onClick={() => {
                                        deletePermissionFunction(row?.id)
                                    }}
                                />
                            </>
                        )}
                    </>
                )
            }
        }
    ]

    const handlePageChange = ({ page, limit, query, sort }) => {
        setCurrentPage(page)
        setLimit(limit)
        setQueries(query)
        if (sort) setSortBy(sort)
    }

    return (
        <>
            <PermissionsGraphs start_date={start_date} end_date={end_date} />
            <JpTableV1
                cols={tabCols}
                fetcher={handlePageChange}
                data={getContractorPermissions?.data}
                total={getContractorPermissions?.count}
                loading={getContractorPermissionsLoading}
                actions={
                    <TabActions
                        start_date={start_date}
                        setStartDate={setStartDate}
                        end_date={end_date}
                        setEndDate={setEndDate}
                        setCurrentPage={setCurrentPage}
                        onClick={() => {
                            setDetailModal(false)
                            setApprovalModal(false)
                            setEditData({})

                            setPermissionModal(true)
                        }}
                        hasWriteTimePermissions={hasWriteTimePermissions}
                    />
                }
            />
            <ModifyPermissionModal
                isOpen={permissionModal}
                toggle={() => setPermissionModal(!permissionModal)}
                refetch={getContractorPermissionsRefetch}
                editData={editData}
                approval={approvalModal}
                view_only={detailModal}
                contractor_id={id}
            />
        </>
    )
}

export default PermissionsDetails
