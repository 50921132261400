import React from 'react'
import { Col, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import AdminAPI from '@src/services/API'
import {
    SpinnerLoader,
    JpStatsIconCard,
    JpCurrencyNumber
} from '@Intelli/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faAlarmClock,
    faChartSimple,
    faDollarSign,
    faFileInvoiceDollar,
    faFileLines,
    faListCheck
} from '@fortawesome/pro-solid-svg-icons'
import useSocket from '@src/hooks/useSocket'

const StatisticsHistorics = () => {
    const { t } = useTranslation()

    const API = new AdminAPI()

    //Total Paid
    const {
        data: getTotalPaidStatistics,
        isError: getTotalPaidStatisticsHasError,
        isLoading: getTotalPaidStatisticsisLoading,
        refetch: getTotalPaidStatisticsRefetch
    } = useQuery(['getTotalPaid'], () => API.getTotalPaidStatistics())

    //Total paid last month
    const {
        data: getLastMonthStatistics,
        isError: getLastMonthStatisticsHasError,
        isLoading: getLastMonthStatisticsLoading,
        refetch: getLastMonthStatisticsRefetch
    } = useQuery(['getLastMonthStatistics'], () => API.getLastMonthStatistics())

    //For ContractType
    const {
        data: forContractType,
        isError: forContractTypeLoadingHasError,
        isLoading: forContractTypeLoading,
        refetch: forContractTypeRefetch
    } = useQuery(['getContractTypeStatistics'], () =>
        API.getContractTypeStatistics()
    )

    useSocket({
        '/payroll/charts/total_paid': {
            fn: value => {
                getTotalPaidStatisticsRefetch()
                forContractTypeRefetch()
            }
        },
        '/payroll/charts/last_month_paid': {
            fn: value => {
                getLastMonthStatisticsRefetch()
            }
        }
    })

    return (
        <Row className="mt-2">
            <Col lg="3" sm="6" xs="12" className="py-50  py-lg-0">
                <JpStatsIconCard
                    loading={getTotalPaidStatisticsisLoading}
                    stat={
                        <JpCurrencyNumber
                            value={getTotalPaidStatistics?.balance}
                        />
                    }
                    statSize="sm"
                    icon={<FontAwesomeIcon icon={faDollarSign} />}
                    iconBgColor="green"
                    iconSize="xl"
                    iconDirection="left"
                    title={`${t('views.payments.statistics.totalPaid')}`}
                    subtitlePercentage={false}
                    spanText={`${getTotalPaidStatistics?.count} ${t(
                        'Contractors'
                    )}`}
                    bodyClassName="py-0"
                />
            </Col>
            <Col lg="3" sm="6" xs="12" className="py-50  py-lg-0">
                <JpStatsIconCard
                    loading={getLastMonthStatisticsLoading}
                    stat={
                        <JpCurrencyNumber
                            value={getLastMonthStatistics?.balance}
                        />
                    }
                    statSize="sm"
                    icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}
                    iconBgColor="info"
                    iconSize="xl"
                    spanText={`${getLastMonthStatistics?.count} ${t(
                        'Contractors'
                    )}`}
                    iconDirection="left"
                    title={`${t(
                        'views.payments.statistics.totalPaidLastMonth'
                    )}`}
                    subtitle={t('views.payments.statistics.lastMonth')}
                    subtitlePercentage={getLastMonthStatistics?.percent}
                    statPosition="bottom"
                    bodyClassName="py-0"
                />
            </Col>
            <Col lg="6" sm="12" className="py-50  py-lg-0 mt-0 mt-sm2">
                <Row className="py-50  py-lg-0 h-100 justify-content-center">
                    <Col xs="6" sm="4" className="py-50 py-lg-0">
                        <JpStatsIconCard
                            loading={forContractTypeLoading}
                            stat={
                                <JpCurrencyNumber
                                    value={forContractType?.classic?.balance}
                                />
                            }
                            statSize="xs"
                            icon={<FontAwesomeIcon icon={faChartSimple} />}
                            iconBgColor="primary"
                            iconSize="lg"
                            iconDirection="left"
                            title={`${t(
                                'views.settings.templates.types.CLASSIC'
                            )}`}
                            subtitlePercentage={false}
                            bodyClassName="p-0"
                        />
                    </Col>
                    <Col xs="6" sm="4" className="py-50 py-lg-0">
                        <JpStatsIconCard
                            loading={forContractTypeLoading}
                            stat={
                                <JpCurrencyNumber
                                    value={forContractType?.time?.balance}
                                />
                            }
                            statSize="xs"
                            icon={<FontAwesomeIcon icon={faAlarmClock} />}
                            iconBgColor="success"
                            iconSize="lg"
                            iconDirection="left"
                            title={`${t(
                                'views.settings.templates.types.TIME'
                            )}`}
                            subtitlePercentage={false}
                            bodyClassName="p-0"
                        />
                    </Col>
                    <Col xs="6" sm="4" className="py-50 py-lg-0">
                        <JpStatsIconCard
                            loading={forContractTypeLoading}
                            stat={
                                <JpCurrencyNumber
                                    value={forContractType?.task?.balance}
                                />
                            }
                            statSize="xs"
                            icon={<FontAwesomeIcon icon={faListCheck} />}
                            iconBgColor="warning"
                            iconSize="lg"
                            iconDirection="left"
                            title={`${t(
                                'views.settings.templates.types.TASKS'
                            )}`}
                            subtitlePercentage={false}
                            bodyClassName="p-0"
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default StatisticsHistorics
