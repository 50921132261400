// ** React Imports
import { Navigate } from 'react-router-dom'
import { useContext, Suspense } from 'react'

// ** Context Imports
import { AbilityContext } from '@src/utility/context/Can'
import { useApplicationModules } from '@src/utility/hooks/useApplicationModules'
import { useSelector } from 'react-redux'

const PrivateRoute = ({ children, route }) => {
  const { modules } = useApplicationModules()
  const { userAuth } = useSelector(state => state.backofficeUser)

  if (userAuth) {
    const availableRoutes = ['admin/home']
    for (const module of modules) {
      for (const metadata of module.metadata) {
        if (metadata?.metadata === 'navLink') {
          availableRoutes.push(metadata.value)
        }
      }
    }

    if (route) {
      let action = null
      let resource = null
      let restrictedRoute = false

      if (route.meta) {
        action = route.meta.action
        resource = route.meta.resource
        restrictedRoute = route.meta.restricted
      }

      const userHasAccess = availableRoutes.some(available_route => {
        return route.path.includes(available_route)
      })

      if (userAuth && restrictedRoute && !userHasAccess) {
        return <Navigate to="/404" />
      }

      if (!userAuth) {
        return <Navigate to="/" />
      }
    }
  }

  return <Suspense fallback={null}>{children}</Suspense>
}

export default PrivateRoute
