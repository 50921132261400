import {
    JpButton,
    JpModal,
    JpLabel,
    JpDatePicker,
    JpText,
    SweetModal,
    JpAvatar,
    ContractorNameInfo
} from '@Intelli/utilities'
import JpAsyncSelectController from '@src/components/input/JpAsyncSelectController'
import JpTextAreaController from '@src/components/input/JpTextAreaController'
import AdminAPI from '@src/services/API'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { Col, FormFeedback, Row } from 'reactstrap'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { downloadFile } from '@src/utility/Files'
import JpSwitchController from '@src/components/input/JpSwitchController'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons'

const PermissionsSchema = yup.object().shape({
    permission_id: yup.object().nullable().required('InputRequired'),
    start_date: yup.string().required('InputRequired'),
    end_date: yup.string().required('InputRequired'),
    description: yup.string().max(200, 'maxTxt').required('InputRequired'),
    pay: yup.boolean()
})

const ModifyPermissionModal = ({
    isOpen,
    toggle,
    editData = {},
    refetch,
    approval = false,
    view_only = false,
    contractor_id
}) => {
    const { t } = useTranslation()
    const API = new AdminAPI()
    const [start_date, setStartDate] = useState(moment().format('YYYY-MM-DD'))
    const [end_date, setEndDate] = useState(moment().format('YYYY-MM-DD'))
    const {
        control,
        reset,
        handleSubmit,
        getValues,
        watch,
        formState: { errors }
    } = useForm({
        defaultValues: {
            start_date,
            end_date,
            pay: false
        },
        resolver: yupResolver(PermissionsSchema)
    })

    const {
        data: getContractorContract,
        isLoading: getContractorContractLoading,
        refetch: getContractorContractRefetch
    } = useQuery(
        ['getContractorPermissions', contractor_id],
        () => API.getContractorContract(contractor_id),
        { enabled: !!contractor_id }
    )

    const { data: permissionData } = useQuery(
        ['getPermission', editData?.contractor_permission_id, isOpen],
        () => API.getContractorPermission(editData?.contractor_permission_id),
        {
            enabled: !!editData?.contractor_permission_id,
            onSuccess: res => {
                setStartDate(res?.result?.start_date)
                setEndDate(res?.result?.end_date)
                reset({
                    permission_id: {
                        value: res?.result?.permission_id,
                        label: res?.result?.permission_name
                    },
                    description: res?.result?.description,
                    start_date: moment(res?.result?.start_date).format(
                        'YYYY-MM-DD'
                    ),
                    end_date: moment(res?.result?.end_date).format(
                        'YYYY-MM-DD'
                    ),
                    pay: !!res?.result?.amount
                })
            }
        }
    )

    const onClose = () => {
        refetch()
        toggle()
        setStartDate(moment().format('YYYY-MM-DD'))
        setEndDate(moment().format('YYYY-MM-DD'))
    }

    const { mutate: addPermission } = useMutation(
        data => API.addContractorPermission(data),
        {
            onSuccess: () => {
                onClose()
                SweetModal(
                    'success',
                    t('Success'),
                    t('views.permissions.addPermission'),
                    t('Ok')
                )
            }
        }
    )

    const { mutate: editPermission } = useMutation(
        data => API.editContractorPermission(data),
        {
            onSuccess: () => {
                onClose()
                SweetModal(
                    'success',
                    t('Success'),
                    t('views.permissions.editPermissionSuccess'),
                    t('Ok')
                )
            }
        }
    )

    const onSubmit = formData => {
        const dataToSend = {
            ...formData,
            permission_id: formData?.permission_id?.value
        }
        if (editData && !!editData?.contractor_permission_id) {
            SweetModal(
                'warning',
                t('views.permissions.editPermission'),
                t('views.permissions.editPermissionTxt'),
                t('Ok'),
                ({ isConfirmed }) => {
                    if (isConfirmed) {
                        editPermission({
                            contractor_permission_id:
                                editData?.contractor_permission_id,
                            data: dataToSend
                        })
                    }
                },
                { showCancelButton: true, cancelButtonText: t('CANCEL') }
            )
        } else {
            addPermission({ ...dataToSend, contractor_id })
        }
    }

    useEffect(() => {
        if (!editData?.contractor_permission_id) {
            reset({
                start_date,
                end_date,
                description: '',
                permission_id: null,
                pay: false
            })
        }
    }, [isOpen])

    return (
        <JpModal
            isOpen={isOpen}
            toggle={onClose}
            headerContent={
                view_only ? (
                    <h4>{t('views.settings.calendar.permissions')}</h4>
                ) : (
                    <h4>
                        {editData?.contractor_permission_id
                            ? t('views.settings.calendar.editPermissions')
                            : t('views.settings.calendar.addPermissions')}
                    </h4>
                )
            }
            bodyContent={
                <Row className="mb-2">
                    <Col sm="12">
                        {!approval && !view_only ? (
                            <JpAsyncSelectController
                                control={control}
                                errors={errors}
                                name="permission_id"
                                title={`${t('views.permissions.permission')}*`}
                                loadOptions={async value => {
                                    const res =
                                        await API.getAvailablePermissions({
                                            query: value
                                        }).then(res => {
                                            return res?.data
                                                ?.sort((a, b) =>
                                                    a.name.localeCompare(b.name)
                                                )
                                                .map(item => {
                                                    return {
                                                        value: item?.id,
                                                        label: item?.name
                                                    }
                                                })
                                        })
                                    return res
                                }}
                            />
                        ) : (
                            <Row>
                                <Col md="6">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <JpAvatar
                                                className="me-1 rounded"
                                                icon={
                                                    <FontAwesomeIcon
                                                        icon={`fa-solid fa-${permissionData?.result?.permission_icon}`}
                                                    />
                                                }
                                                color={'light-info'}
                                            />
                                        </div>
                                        <Col className="text-truncate">
                                            <JpLabel
                                                labelOptions={{
                                                    for: 'permission_id'
                                                }}
                                            >
                                                {t(
                                                    'views.permissions.permission'
                                                )}
                                            </JpLabel>

                                            <ContractorNameInfo
                                                Tag={'div'}
                                                classNameContainer={
                                                    'd-flex flex-column'
                                                }
                                                largeName={
                                                    permissionData?.result
                                                        ?.permission_name
                                                }
                                                contractor_id={
                                                    permissionData?.result
                                                        ?.permission_id
                                                }
                                            />
                                        </Col>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <JpAvatar
                                                className="me-1 rounded"
                                                imgClassName="cursor-default"
                                                color={`${
                                                    permissionData?.result?.pay
                                                        ? 'light-success'
                                                        : 'light-danger'
                                                }`}
                                                icon={
                                                    <FontAwesomeIcon
                                                        icon={faDollarSign}
                                                    />
                                                }
                                            />
                                        </div>
                                        <Col className="text-truncate">
                                            <JpLabel
                                                labelOptions={{
                                                    for: 'permission_id'
                                                }}
                                            >
                                                {t('views.time.paid')}
                                            </JpLabel>

                                            <JpText
                                                type="span-table-text"
                                                children={t(
                                                    `views.permissions.${
                                                        permissionData?.result
                                                            ?.pay
                                                            ? 'permissionsPaid'
                                                            : 'permissionsNoPaid'
                                                    }`
                                                )}
                                            />
                                        </Col>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Col>
                    <Col sm="6" className="mt-1">
                        {!approval && !view_only ? (
                            <>
                                <JpLabel labelOptions={{ for: 'start_date' }}>
                                    {t(
                                        'views.classicContract.labels.startDate'
                                    )}
                                </JpLabel>
                                <Controller
                                    control={control}
                                    name="start_date"
                                    render={({ field }) => (
                                        <JpDatePicker
                                            id="start_date"
                                            
                                            maxDate={end_date}
                                            setPicker={date => {
                                                setStartDate(
                                                    moment(date).format(
                                                        'YYYY-MM-DD'
                                                    )
                                                )
                                                field.onChange(
                                                    date
                                                        ? date.format(
                                                              'YYYY-MM-DD'
                                                          )
                                                        : ''
                                                )
                                            }}
                                            className={`enabled-joobpay ps-0 ${
                                                errors.end_date
                                                    ? 'is-invalid'
                                                    : 'mb-1'
                                            }`}
                                            {...field}
                                        />
                                    )}
                                />
                            </>
                        ) : (
                            <div className="d-flex align-items-center">
                                <div>
                                    <JpAvatar
                                        className="me-1 rounded"
                                        icon={
                                            <FontAwesomeIcon
                                                icon={`fa-solid fa-calendar`}
                                            />
                                        }
                                        color={'light-primary'}
                                    />
                                </div>
                                <Col className="text-truncate">
                                    <JpLabel
                                        labelOptions={{ for: 'start_date' }}
                                    >
                                        {t(
                                            'views.classicContract.labels.startDate'
                                        )}
                                    </JpLabel>
                                    <JpText
                                        type="span-table-text"
                                        children={getValues('start_date') || ''}
                                    />
                                </Col>
                            </div>
                        )}
                    </Col>
                    <Col sm="6" className="mt-1">
                        {!approval && !view_only ? (
                            <>
                                <JpLabel labelOptions={{ for: 'end_date' }}>
                                    {t('views.classicContract.labels.endDate')}
                                </JpLabel>
                                <Controller
                                    control={control}
                                    name="end_date"
                                    render={({ field }) => (
                                        <JpDatePicker
                                            id="end_date"
                                            minDate={moment(start_date).format(
                                                'YYYY-MM-DD'
                                            )}
                                            setPicker={date => {
                                                setEndDate(
                                                    moment(date).format(
                                                        'YYYY-MM-DD'
                                                    )
                                                )
                                                field?.onChange(
                                                    date
                                                        ? date.format(
                                                              'YYYY-MM-DD'
                                                          )
                                                        : ''
                                                )
                                            }}
                                            className={`enabled-joobpay ps-0 ${
                                                errors.end_date
                                                    ? 'is-invalid'
                                                    : 'mb-1'
                                            }`}
                                            {...field}
                                        />
                                    )}
                                />
                            </>
                        ) : (
                            <div className="d-flex align-items-center">
                                <div>
                                    <JpAvatar
                                        className="me-1 rounded"
                                        icon={
                                            <FontAwesomeIcon
                                                icon={`fa-solid fa-calendar`}
                                            />
                                        }
                                        color={'light-primary'}
                                    />
                                </div>
                                <Col className="text-truncate">
                                    <JpLabel labelOptions={{ for: 'end_date' }}>
                                        {t(
                                            'views.classicContract.labels.endDate'
                                        )}
                                    </JpLabel>
                                    <JpText
                                        type="span-table-text"
                                        children={getValues('end_date') || ''}
                                    />
                                </Col>
                            </div>
                        )}
                    </Col>
                    <Col sm="12" className="my-75">
                        {!approval && !view_only ? (
                            <JpTextAreaController
                                name="description"
                                optionsInput={{
                                    placeholder: t(
                                        'views.newContract.steps.contract.clauseModal.form.description'
                                    )
                                }}
                                control={control}
                                errors={errors}
                                title={`${t(
                                    'views.newContract.steps.contract.clauseModal.form.description'
                                )}*`}
                                ns="global"
                            />
                        ) : (
                            <div className="d-flex align-items-center">
                                <div>
                                    <JpAvatar
                                        className="me-1 rounded"
                                        icon={
                                            <FontAwesomeIcon
                                                icon={`fa-solid fa-message-text`}
                                            />
                                        }
                                        color={'light-info'}
                                    />
                                </div>
                                <Col className="text-truncate">
                                    <JpLabel
                                        labelOptions={{ for: 'description' }}
                                    >
                                        {t(
                                            'views.newContract.steps.contract.clauseModal.form.description'
                                        )}
                                    </JpLabel>
                                    <JpText
                                        type="span-table-text"
                                        children={
                                            getValues('description') || ''
                                        }
                                    />
                                </Col>
                            </div>
                        )}
                    </Col>
                    <Col sm="12">
                        {!view_only &&
                            getContractorContract?.name === 'TIME' && (
                                <JpSwitchController
                                    className="form-switch form-check-primary"
                                    name="pay"
                                    label={t('views.time.paid')}
                                    control={control}
                                    optionsLabel={{
                                        className: 'form-check-label text-break'
                                    }}
                                    optionsInput={{
                                        defaultChecked: watch('pay'),
                                        checked: watch('pay')
                                    }}
                                />
                            )}

                        {view_only && (
                            <Row>
                                {permissionData?.result
                                    ?.support_media_location && (
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <JpAvatar
                                                className="me-1 rounded"
                                                icon={
                                                    <FontAwesomeIcon
                                                        icon={`fa-solid fa-download`}
                                                    />
                                                }
                                                color={'light-info'}
                                            />
                                        </div>
                                        <div className="d-flex flex-column">
                                            <JpLabel
                                                labelOptions={{
                                                    for: 'description'
                                                }}
                                            >
                                                {t('attached')}
                                            </JpLabel>
                                            <JpButton
                                                color="link"
                                                type="download"
                                                className="p-0"
                                                options={{
                                                    onClick: () => {
                                                        downloadFile(
                                                            permissionData
                                                                ?.result
                                                                ?.support_media_location,
                                                            `${permissionData?.result?.permission_name}`
                                                        )
                                                    }
                                                }}
                                            />
                                            
                                        </div>
                                    </div>
                                )}
                            </Row>
                        )}
                    </Col>
                </Row>
            }
            footerContent={
                <>
                    {!view_only ? (
                        <>
                            <JpButton
                                type="cancel"
                                options={{ onClick: onClose }}
                            />
                            <JpButton
                                type="save"
                                options={{ onClick: handleSubmit(onSubmit) }}
                            />
                        </>
                    ) : (
                        <JpButton
                            color="info"
                            type="close"
                            options={{ onClick: onClose, iconless: true }}
                        />
                    )}
                </>
            }
        />
    )
}

export default ModifyPermissionModal
