import {
    JpStatsIconCard,
    JpCurrencyNumber,
    SpinnerLoader,
    JpPieCardTime,
    parseMinutesToHours,
    JpVerticalBarsCard,
    JpPercentageText,
    JpDonutWithTextGraph,
    JpText,
    JpAdvanceCard,
    TIME_PERMISSIONS_STATUS_ARRAY,
    TIME_PERMISSIONS_STATUS,
    JpBarsCardV1
} from '@Intelli/utilities'
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useSocket from '@src/hooks/useSocket'
import AdminAPI from '@src/services/API'
import datesInRange from '@src/utility/datesInRange'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'

const PermissionsGraphs = ({ start_date, end_date }) => {
    const { t } = useTranslation()
    const API = new AdminAPI()
    const { id } = useParams()

    const {
        data: permissionsAmount,
        isLoading: permissionsAmountLoading,
        refetch: permissionsAmountRefetch
    } = useQuery(['permissionsAmount'], () =>
        id
            ? API.getPermissionAmountGraph({
                  start_date,
                  end_date,
                  contractor_id: id
              })
            : API.getPermissionAmountGraph({
                  start_date,
                  end_date
              })
    )

    const {
        data: getPermissionTypesGraph,
        isLoading: getPermissionTypesGraphLoading,
        refetch: getPermissionTypesGraphRefetch
    } = useQuery(['getPermissionTypesGraph'], () =>
        id
            ? API.getPermissionTypesGraph({
                  start_date,
                  end_date,
                  contractor_id: id
              })
            : API.getPermissionTypesGraph({
                  start_date,
                  end_date
              })
    )

    const {
        data: permissionsByStatus,
        isLoading: permissionsByStatusLoading,
        refetch: permissionsByStatusRefetch
    } = useQuery(['permissionsByStatus'], () =>
        id
            ? API.getPermissionsByState({
                  start_date,
                  end_date,
                  contractor_id: id
              })
            : API.getPermissionsByState({
                  start_date,
                  end_date
              })
    )

    const {
        data: permissionsByMonths,
        isLoading: permissionsByMonthsLoading,
        refetch: permissionsByMonthsRefetch
    } = useQuery(['permissionsByMonths'], () =>
        id
            ? API.getPermissionsByMonths({
                  start_date,
                  end_date,
                  contractor_id: id
              })
            : API.getPermissionsByMonths({
                  start_date,
                  end_date
              })
    )

    useEffect(() => {
        permissionsAmountRefetch()
        getPermissionTypesGraphRefetch()
        permissionsByStatusRefetch()
        permissionsByMonthsRefetch()
    }, [start_date, end_date])

    useSocket(
        id
            ? {
                  [`/time/permissions_contractors/${id}/charts/amount_to_pay`]:
                      {
                          fn: value => {
                              if (
                                  datesInRange(
                                      value.payload.permissions,
                                      start_date,
                                      end_date
                                  )
                              ) {
                                  permissionsAmountRefetch()
                              }
                          }
                      },
                  [`/time/permissions_contractors/${id}/charts/grouped_by_state`]:
                      {
                          fn: value => {
                              if (
                                  datesInRange(
                                      value.payload.permissions,
                                      start_date,
                                      end_date
                                  )
                              ) {
                                  permissionsByStatusRefetch()
                              }
                          }
                      },
                  [`/time/permissions_contractors/${id}/charts/top_five`]: {
                      fn: value => {
                          if (
                              datesInRange(
                                  value.payload.permissions,
                                  start_date,
                                  end_date
                              )
                          ) {
                              getPermissionTypesGraphRefetch()
                          }
                      }
                  },
                  [`/time/permissions_contractors/${id}/charts/grouped_by_month`]:
                      {
                          fn: value => {
                              if (
                                  datesInRange(
                                      value.payload.permissions,
                                      start_date,
                                      end_date
                                  )
                              ) {
                                  permissionsByMonthsRefetch()
                              }
                          }
                      }
              }
            : {
                  '/time/permissions_contractors/charts/amount_to_pay': {
                      fn: value => {
                          if (
                              datesInRange(
                                  value.payload.permissions,
                                  start_date,
                                  end_date
                              )
                          ) {
                              permissionsAmountRefetch()
                          }
                      }
                  },
                  '/time/permissions_contractors/charts/grouped_by_state': {
                      fn: value => {
                          if (
                              datesInRange(
                                  value.payload.permissions,
                                  start_date,
                                  end_date
                              )
                          ) {
                              permissionsByStatusRefetch()
                          }
                      }
                  },
                  '/time/permissions_contractors/charts/top_five': {
                      fn: value => {
                          if (
                              datesInRange(
                                  value.payload.permissions,
                                  start_date,
                                  end_date
                              )
                          ) {
                              getPermissionTypesGraphRefetch()
                          }
                      }
                  },
                  '/time/permissions_contractors/charts/grouped_by_month': {
                      fn: value => {
                          if (
                              datesInRange(
                                  value.payload.permissions,
                                  start_date,
                                  end_date
                              )
                          ) {
                              permissionsByMonthsRefetch()
                          }
                      }
                  }
              },
        [start_date, end_date]
    )

    const transformTypesPermissions = getPermissionTypesGraph?.permissions
        ?.sort((a, b) => b.count - a.count)
        ?.slice(0, 3)
        .map(item => ({
            id: item?.permission?.id,
            name: item?.permission?.name,
            count: item?.count,
            media: {
                icon: item?.permission?.icon ? (
                    <i className={`fa-solid fa-${item?.permission?.icon}`}></i>
                ) : null
            },
            percent: item?.percent
        }))

    return (
        <Row className="mb-2">
            <Col lg="2" sm="6" xs="12" className="mb-1 mb-lg-0">
                <JpStatsIconCard
                    stat={
                        <div className="d-flex flex-column">
                            <JpCurrencyNumber
                                value={permissionsAmount?.current?.amount}
                            />
                            <small className="text-success">
                                {`${permissionsAmount?.current?.hours}h`}
                            </small>
                        </div>
                    }
                    statSize="sm"
                    icon={<FontAwesomeIcon icon={faDollarSign} />}
                    iconBgColor="green"
                    iconSize="lg"
                    iconDirection="top"
                    title={`${t('views.time.graphs.remunerated')}`}
                    subtitle={t('views.time.graphs.lastPeriod')}
                    subtitlePercentage={parseInt(permissionsAmount?.percent)}
                    statPosition="bottom"
                    bodyClassName="justify-content-sm-start py-0 "
                    className={' text-start'}
                    loading={permissionsAmountLoading}
                />
            </Col>
            <Col lg="3" sm="6" xs="12" className="mb-1 mb-lg-0">
                <JpDonutWithTextGraph
                    isLoading={permissionsByStatusLoading}
                    title={`${t('views.settings.calendar.permissions')} ${t(
                        'views.permissions.byStates'
                    ).toLocaleLowerCase()}`}
                    /* bodyClassName="d-flex justify-content-center align-items-center" */
                    classNameCard="mb-0"
                    series={
                        !permissionsByStatusLoading
                            ? TIME_PERMISSIONS_STATUS_ARRAY.sort(
                                  (a, b) =>
                                      permissionsByStatus?.states[b]?.count -
                                      permissionsByStatus?.states[a]?.count
                              ).map(item =>
                                  parseFloat(
                                      permissionsByStatus?.states[item]?.percent
                                  )
                              )
                            : []
                    }
                    labels={
                        !permissionsByStatusLoading
                            ? Object.keys(permissionsByStatus?.states)
                                  .sort(
                                      (a, b) =>
                                          permissionsByStatus?.states[b]
                                              ?.count -
                                          permissionsByStatus?.states[a]?.count
                                  )
                                  .map(item =>
                                      t(`views.permissions.status.${item}`)
                                  )
                            : []
                    }
                    colors={
                        !permissionsByStatusLoading
                            ? Object.keys(permissionsByStatus?.states)
                                  .sort(
                                      (a, b) =>
                                          permissionsByStatus?.states[b]
                                              ?.count -
                                          permissionsByStatus?.states[a]?.count
                                  )
                                  .map(
                                      item =>
                                          TIME_PERMISSIONS_STATUS[item]?.color
                                  )
                            : null
                    }
                    defaults={{
                        label: t('PENDING'),
                        value: parseFloat(
                            permissionsByStatus?.states['PENDING']?.percent
                        )
                    }}
                    // height={160}
                />
            </Col>
            <Col lg="3" sm="6" className="mb-1 mb-lg-0">
                <JpAdvanceCard
                    title={t('views.permissions.permissionsTypes')}
                    data={transformTypesPermissions}
                    loading={getPermissionTypesGraphLoading}
                    customTooltipPercent={t(
                        'views.settings.calendar.permissions'
                    )}
                />
            </Col>
            <Col lg="4" sm="6" className="mb-1 mb-lg-0">
                <JpBarsCardV1
                    title={t('views.permissions.permissionsApproved')}
                    cardClassName={'mb-0'}
                    /* percentage={permissionsByMonths?.months[0]?.percent} */
                    stat={`${permissionsByMonths?.total} ${t(
                        'views.settings.calendar.permissions'
                    )} ${t(
                        'views.permissions.inThePeriod'
                    ).toLocaleLowerCase()}`}
                    statClassName={'mb-0'}
                    lastPeriodText={t('views.time.graphs.lastPeriod')}
                    barsData={[
                        {
                            data: permissionsByMonths?.months
                                ?.map(item => {
                                    return item?.paid?.percent
                                })
                                ?.reverse()
                        },
                        {
                            data: permissionsByMonths?.months
                                ?.map(item => {
                                    return item?.not_paid?.percent
                                })
                                ?.reverse()
                        }
                    ]}
                    colors={['#0198FE', '#28c76f']}
                    xTooltipFormatter={(
                        val,
                        {
                            seriesIndex,
                            w: {
                                globals: { categoryLabels }
                            }
                        }
                    ) => {
                        const series = t(
                            `views.permissions.status.${
                                ['APPROVED_PAY', 'APPROVED_NOT_PAY'][
                                    seriesIndex
                                ]
                            }`
                        )
                        return `${series} - ${categoryLabels[val - 1]}`
                    }}
                    yTooltipFormatter={val => `${val}%`}
                    seriesLabels={permissionsByMonths?.months
                        .map(item => {
                            return moment(item?.date).format('MMM')
                        })
                        .reverse()}
                    loading={permissionsByMonthsLoading}
                    stacked
                    chartHeight={145}
                />
            </Col>
        </Row>
    )
}

export default PermissionsGraphs
