import { useEffect, useState } from 'react'
import { HasPermissionModules } from '@Intelli/utilities'
import { useApplicationModules } from './ApplicationModules.hook'

const usePermissionsInvoices = () => {
    const [hasWritePermission, setHasWritePermission] = useState(false)
    const { modules } = useApplicationModules()
    useEffect(() => {
        setHasWritePermission(
            HasPermissionModules.WriteUpdate(modules, [
                'INVOICES',
                'INVOICES-INVOICE'
            ])
        )
    }, [])

    return { hasWritePermission }
}

export default usePermissionsInvoices
