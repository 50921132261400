import { Form, FormFeedback, Input, Label } from 'reactstrap'
import { Controller, useForm } from 'react-hook-form'
import { useState } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import AdminAPI from '@src/services/API'
import { useParams } from 'react-router-dom'
import { JpFileModal, SweetModal, SpinnerLoader } from '@Intelli/utilities'

const CreateTemplateModal = ({
    isOpen,
    toggle,
    setSelectedTemplate,
    setTemporalTemplate,
    setRefetchTemplates
}) => {
    const { t } = useTranslation()
    const API = new AdminAPI()
    const { id } = useParams()
    const [files, setFiles] = useState([])
    const [fileInvalid, setFileInvalid] = useState(false)

    const SignupSchema = yup.object().shape({
        name: yup.string().required('TEMPLATE_NAME.required'),
        /* is_template: yup.boolean().oneOf([true, false], 'IS_TEMPLATE.required') */
    })

    const {
        control,
        reset,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues: {
            name: '',
            is_template: true
        },
        resolver: yupResolver(SignupSchema)
    })

    const { mutate: createTemplate, isLoading: createTemplateIsLoading } =
        useMutation(data => API.createContractorTemplate(data), {
            onSuccess: (response, vars) => {
                reset()
                setFiles([])
                if (vars.data.is_temporal) {
                    setTemporalTemplate(response.id)
                    localStorage.setItem('temporalTemplate', response.id)
                }
                // setTemporalTemplate(response.id)
                // localStorage.setItem('temporalTemplate', response.id)
                setRefetchTemplates()
                toggle()
                SweetModal(
                    'success',
                    t('Success'),
                    t('modals.createdTemplate'),
                    t('Ok')
                )
            }
        })

    const onSubmit = data => {
        if (!files.length) {
            setFileInvalid(true)
            return
        }
        if (data.is_template) {
            createTemplate({
                data: { name: data.name, file: files[0], is_temporal: false },
                contractor_id: id
            })
        } else {
            createTemplate({
                data: { name: data.name, file: files[0], is_temporal: true },
                contractor_id: id
            })
        }
    }

    if (createTemplateIsLoading) {
        return <SpinnerLoader />
    }

    return (
        <JpFileModal
            isOpen={isOpen}
            toggle={() => {
                toggle()
                reset()
            }}
            files={files}
            setFiles={setFiles}
            fileInvalid={fileInvalid}
            setFileInvalid={setFileInvalid}
            fileInputOptions={{
                name: 'file'
            }}
            onSubmit={handleSubmit(onSubmit)}
            headerContent={
                <>{t('views.settings.templates.form.titleCreate')}</>
            }
            bodyContent={
                <>
                    <Form
                        className="mb-1"
                        onSubmit={handleSubmit(onSubmit)}
                        id="templateForm"
                    >
                        <Label for="name">
                            {t('views.settings.templates.form.name')}
                        </Label>
                        <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    invalid={errors.name && true}
                                    id="name"
                                    type="text"
                                    {...field}
                                />
                            )}
                        />
                        <FormFeedback invalid>
                            {t(`form.templates.${errors.name?.message}`, {
                                ns: 'errors'
                            })}
                        </FormFeedback>
                        <div className="form-switch form-check-info mt-2">
                            <Controller
                                name="is_template"
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        defaultChecked
                                        type="switch"
                                        className="me-1"
                                        {...field}
                                    />
                                )}
                            />
                            <Label
                                for="is_template"
                                className="form-check-label"
                            >
                                {t(
                                    'views.newContract.steps.contract.form.isTemplate'
                                )}
                            </Label>
                        </div>
                        {/* <FormFeedback invalid>
                            {t(
                                `form.templates.${errors.is_template?.message}`,
                                { ns: 'errors' }
                            )}
                        </FormFeedback> */}
                    </Form>
                </>
            }
        />
    )
}

export default CreateTemplateModal
