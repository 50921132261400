import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form, FormFeedback, Input, Label } from 'reactstrap'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { JpModal, JpButton } from '@Intelli/utilities'

const MAX_LENGTH = 1600

const ContractClauseModal = ({ isOpen, toggle, handleNewClause }) => {
    const { t } = useTranslation()

    const SignupSchema = yup.object().shape({
        title: yup.string().required('CLAUSE_TITLE.required'),
        description: yup
            .string()
            .required('CLAUSE_DESCRIPTION.required')
            .max(MAX_LENGTH, 'CLAUSE_DESCRIPTION.max')
    })

    const {
        control,
        reset,
        watch,
        getValues,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues: {
            title: '',
            description: ''
        },
        resolver: yupResolver(SignupSchema)
    })

    const onSubmit = data => {
        reset()
        toggle()
        handleNewClause(data)
    }

    return (
        <JpModal
            isOpen={isOpen}
            toggle={() => {
                reset()
                toggle()
            }}
            headerContent={
                <>{t('views.newContract.steps.contract.clauseModal.title')}</>
            }
            bodyContent={
                <Form
                    className="mb-1"
                    onSubmit={handleSubmit(onSubmit)}
                    id="templateForm"
                >
                    <Label for="title">
                        {t(
                            'views.newContract.steps.contract.clauseModal.form.title'
                        )}{' '}
                        *
                    </Label>
                    <Controller
                        name="title"
                        control={control}
                        render={({ field }) => (
                            <Input
                                invalid={errors.title && true}
                                id="title"
                                type="text"
                                {...field}
                            />
                        )}
                    />
                    <FormFeedback invalid>
                        {t(`form.templates.${errors.title?.message}`, {
                            ns: 'errors'
                        })}
                    </FormFeedback>
                    <Label for="clause" className="mt-1">
                        {t(
                            'views.newContract.steps.contract.clauseModal.form.description'
                        )}{' '}
                        *
                    </Label>
                    <Controller
                        name="description"
                        control={control}
                        render={({ field }) => (
                            <Input
                                invalid={errors.description && true}
                                id="description"
                                type="textarea"
                                style={{
                                    minHeight: '155px',
                                    maxHeight: '200px'
                                }}
                                {...field}
                            />
                        )}
                    />
                    <span
                        className={`textarea-counter-value float-end ${
                            watch('description').length > MAX_LENGTH
                                ? 'bg-warning'
                                : 'bg-info'
                        }`}
                    >
                        {`${getValues('description').length}/${MAX_LENGTH}`}
                    </span>
                    <FormFeedback invalid>
                        {t(`form.templates.${errors.description?.message}`, {
                            ns: 'errors'
                        })}
                    </FormFeedback>
                </Form>
            }
            footerContent={
                <>
                    <JpButton
                        type="cancel"
                        options={{
                            onClick: () => {
                                reset()
                                toggle()
                            }
                        }}
                    />
                    <JpButton
                        type="save"
                        options={{
                            onClick: handleSubmit(onSubmit),
                            iconless: false
                        }}
                    />
                </>
            }
        />
    )
}

export default ContractClauseModal
