import {
    JpCustomTooltip,
    JpText,
    RatingStars,
    productivityColor,
    ProfileHeader,
    JpAvatar
} from '@Intelli/utilities'
import RatingModalInfo from '@src/components/projects/RatingModalInfo'
import AdminAPI from '@src/services/API'
import { parseName } from '@src/utility/Utils'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CustomHeader = ({ contractorData }) => {
    const { t } = useTranslation()

    return (
        <div className="d-flex align-items-center gap-1 flex-wrap border-bottom mb-1">
            <ProfileHeader
                Avatar={
                    contractorData.user_photo?.photo ? (
                        <div className="d-flex align-items-end">
                            <JpAvatar
                                img={contractorData.user_photo?.photo}
                                imgHeight={100}
                                imgWidth={100}
                                imgClassName="avatar-cover cursor-default"
                                className="cursor-default"
                                contentStyles={{
                                    cursor: 'default'
                                }}
                            />
                        </div>
                    ) : (
                        <div className="d-flex align-items-end">
                            <JpAvatar
                                content={parseName(
                                    contractorData.first_name,
                                    contractorData.last_name
                                )}
                                initials
                                color="primary"
                                contentStyles={{
                                    height: 100,
                                    width: 100,
                                    fontWeight: 'bold',
                                    fontSize: 30,
                                    cursor: 'default'
                                }}
                                imgClassName="rounded cursor-default"
                            />
                        </div>
                    )
                }
                Name={parseName(
                    contractorData.first_name,
                    contractorData.last_name
                )}
                Details={''}
                detailsDiv={
                    <div>
                        <RatingStars
                            initialRating={contractorData.rating}
                            readonly={true}
                        />
                    </div>
                }
            />

            <div className="d-flex align-items-center gap-1">
                <div className="d-flex gap-50 align-items-center">
                    <JpAvatar
                        icon={<FontAwesomeIcon icon="fa-solid fa-star" />}
                        color={'yellow'}
                    />
                    <JpText
                        type="p-text"
                        className={'fw-bold'}
                        children={`${contractorData.qualifications_count} ${t(
                            'views.projects.ratings'
                        )}`}
                    />
                </div>

                <div className="d-flex gap-50 align-items-center">
                    <JpAvatar
                        icon={
                            <FontAwesomeIcon icon="fa-solid fa-arrow-trend-up" />
                        }
                        color={'green'}
                    />
                    <JpText
                        type="p-text"
                        className={`fw-bold text-${productivityColor(
                            contractorData.productivity
                        )}`}
                        children={`${parseFloat(contractorData.productivity)}%`}
                    />

                    <JpText
                        type="p-text"
                        className={'fw-bold'}
                        children={`${t('Productivity')}`}
                    />
                </div>
            </div>
        </div>
    )
}

const RatingForProjects = ({ rating }) => {
    const [modalRateInfo, setModalRateInfo] = useState(false)
    const [ratingData, setRatingData] = useState(null)
    const [contractorData, setContractorData] = useState(null)

    const { t } = useTranslation()
    const API = new AdminAPI()
    const { id } = useParams()

    const {
        mutateAsync: getAllContractorRating,
        isLoading: getAllContractorRatingLoading
    } = useMutation(data => API.getAllContractorRating(data))

    const handleClick = async () => {
        const { qualifications, contractor } = await getAllContractorRating(id)

        const allRatings = qualifications?.map(rating => ({
            comment: rating?.comment,
            rating: rating?.rating,
            budget: rating?.budget_excuted,
            user_photo: rating?.user_photo,
            first_name: rating?.first_name,
            last_name: rating?.last_name,
            position: rating?.position,
            rating_date: rating.created_at,
            name_project: rating.name_project,
            name_task: rating.name_task,
            url_link: rating?.contractor_id
                ? `/admin/contractors/profile/${rating.contractor_id}`
                : `/admin/settings/users/profile/${rating?.user_id}`
        }))

        setRatingData({
            allRatings,
            rating_of: parseName(contractor?.first_name, contractor?.last_name)
        })
        setContractorData(contractor)

        setModalRateInfo(true)
    }

    const onCloseModalRateInfo = () => {
        setModalRateInfo(false)
        setRatingData(null)
    }

    return (
        <>
            <div className="mt-50 d-flex align-items-center gap-1">
                {getAllContractorRatingLoading ? (
                    <Spinner color="primary" size="sm">
                        {''}
                    </Spinner>
                ) : (
                    <JpCustomTooltip
                        id={`id-rating`}
                        tooltipText={`${t('views.projects.seeRatings')}`}
                    >
                        <div
                            onClick={handleClick}
                            id={`id-rating`}
                            className="cursor-pointer"
                        >
                            <RatingStars
                                initialRating={rating}
                                readonly={true}
                            />
                        </div>
                    </JpCustomTooltip>
                )}
            </div>

            <RatingModalInfo
                isOpen={modalRateInfo}
                onClose={onCloseModalRateInfo}
                ratingData={ratingData}
                customHeader={<CustomHeader contractorData={contractorData} />}
            />
        </>
    )
}

export default RatingForProjects
