import { configureStore } from '@reduxjs/toolkit'
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    createTransform
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootReducer from './rootReducer'

const transform = createTransform(null, (state, key) => {
    // console.log('key', key)
    // console.log('state', state)
    return state
})

const persistConfig = {
    key: 'admin',
    version: 1,
    transforms: [transform],
    blacklist: ['layout'],
    whitelist: [
        'navbar',
        'breadcrumbs',
        'getUrlPdf',
        'payrollDetailPosition',
        'backofficeUser',
        'getStatePayroll',
        'adminRoutes',
        'signContractModal',
        'hasRL',
        'projectInfoRow',
        'taskInfoRow'
    ],
    storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER
                ]
            }
        })
})

const persistor = persistStore(store)

export { store, persistor }
