import { getDataSet, reduce } from 'iso3166-2-db'
import { Col, Row, CardTitle } from 'reactstrap'
import i18n from 'i18next'
import Avatar from '@src/@core/components/avatar'
import { useTranslation } from 'react-i18next'
import {
    parseName,
    SpinnerLoader,
    JpText,
    JpLabel,
    dateLocalizer
} from '@Intelli/utilities'
import parsePhoneNumberFromString, { getPhoneCode } from 'libphonenumber-js'
import '../../../../@core/scss/react/libs/flatpickr/flatpickr.scss'
import ReactCountryFlag from 'react-country-flag'

const ContractorInfo = ({ contractorData }) => {
    const { t } = useTranslation()

    const listOfCountries = reduce(getDataSet(), i18n.language)

    if (!contractorData) return <SpinnerLoader />

    const countryCode = contractorData?.phone
        ? parsePhoneNumberFromString(contractorData.phone).country
        : 'US'

    return (
        <>
            <Col md="2" className="text-center pt-3">
                {contractorData?.photo ? (
                    <Avatar
                        img={contractorData?.photo?.photo}
                        // content="a"
                        size="xxxl"
                        imgHeight={'105px'}
                        imgWidth={'105px'}
                        color="light-primary"
                        imgClassName="cover"
                    />
                ) : (
                    <Avatar
                        content={parseName(
                            contractorData?.user?.first_name ||
                                contractorData?.email,
                            contractorData?.user?.last_name
                        )}
                        initials
                        size="xxxl"
                        color="light-primary"
                    />
                )}
            </Col>
            <Col>
                <CardTitle className="border-bottom mb-2">{`${t(
                    'views.newContract.steps.contractor.basicInfo'
                )}`}</CardTitle>
                <Row id="contractorForm" className="pt-50">
                    <Col sm="6" lg="4" md="4" className="mb-1">
                        <JpLabel>{`${t('FIRST_NAME')}`}</JpLabel>
                        <JpText type="p-text">
                            {contractorData.user.first_name}
                        </JpText>
                    </Col>
                    <Col sm="6" lg="4" md="4" className="mb-1">
                        <JpLabel>{`${t('LAST_NAME')}`}</JpLabel>
                        <JpText type="p-text">
                            {contractorData.user.last_name}
                        </JpText>
                    </Col>
                    <Col sm="6" lg="4" className="mb-1">
                        <JpLabel>{`${t('BIRTHDAY')}`}</JpLabel>
                        <JpText type="p-text">
                            {contractorData?.user_metadata?.birthday
                                ? dateLocalizer.getIntlLongDate(
                                      contractorData?.user_metadata?.birthday
                                  )
                                : 'N/A'}
                        </JpText>
                    </Col>
                    <Col sm="6" lg="4" className="mb-1">
                        <JpLabel>{`${t('EMAIL')}`}</JpLabel>
                        <JpText type="p-text">{contractorData.email}</JpText>
                    </Col>
                    <Col md="6" lg="4" className="mb-1">
                        <JpLabel>{`${t('PHONE_NUMBER')}`}</JpLabel>
                        <JpText type="p-text">
                            {contractorData?.phone
                                ? `+ ${getPhoneCode(countryCode)} ${
                                      parsePhoneNumberFromString(
                                          contractorData.phone
                                      ).nationalNumber
                                  }`
                                : ''}
                        </JpText>
                    </Col>
                </Row>
                <CardTitle className="border-bottom my-2">{`${t(
                    'views.newContract.steps.contractor.addressDetails'
                )}`}</CardTitle>
                <Row>
                    <Col sm="6" lg="4" className="mb-1">
                        <JpLabel>{`${t('COUNTRY')}`}</JpLabel>
                        <div className="d-flex align-items-center">
                            <ReactCountryFlag
                                svg
                                className="country-flag flag-icon me-75"
                                countryCode={contractorData?.address?.country}
                            />
                            <JpText type="p-text">
                                {`${
                                    listOfCountries[
                                        contractorData?.address?.country
                                    ]?.name
                                }`}
                            </JpText>
                        </div>
                    </Col>
                    <Col sm="6" lg="4" className="mb-1">
                        <JpLabel>{`${t('STATE_REGION')}`}</JpLabel>
                        <JpText type="p-text">
                            {listOfCountries[
                                contractorData?.address?.country
                            ]?.regions?.find(
                                region =>
                                    region.iso ===
                                    contractorData?.address.region
                            )?.name || ''}
                        </JpText>
                    </Col>
                    <Col sm="6" lg="4" className="mb-1">
                        <JpLabel>{`${t('CITY')}`}</JpLabel>
                        <JpText type="p-text">
                            {`${contractorData?.address?.city}`}
                        </JpText>
                    </Col>
                    <Col sm="6" lg="4" className="mb-1">
                        <JpLabel>{`${t('ADDRESS_LINE_1')}`}</JpLabel>
                        <JpText type="p-text">
                            {`${contractorData?.address?.first_line}`}
                        </JpText>
                    </Col>
                    <Col sm="6" lg="4" className="mb-1">
                        <JpLabel>{`${t('ADDRESS_LINE_2')}`}</JpLabel>
                        <JpText type="p-text">
                            {
                            contractorData?.address?.second_line ?
                            `${contractorData?.address?.second_line}`
                            : '-' }
                        </JpText>
                    </Col>
                    <Col sm="6" lg="4" className="mb-1">
                        <JpLabel>{`${t('ZIP_CODE')}`}</JpLabel>
                        <JpText type="p-text">
                            {`${contractorData?.address?.zip_code}`}
                        </JpText>
                    </Col>
                </Row>
            </Col>
        </>
    )
}

export default ContractorInfo
