import { Col, Card, CardHeader, CardBody, Row } from 'reactstrap'

import { JpAvatar, parseName, dateLocalizer } from '@Intelli/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { productivityStatesIcons } from '@src/constants/icons/productivityStatesIcons'

const types = {
    PRODUCTIVE: { color: 'light-green', icon: 'person-digging' },
    UNPRODUCTIVE: { color: 'light-danger', icon: 'stopwatch' },
    NEUTRAL: { color: 'light-info', icon: 'person' }
}

const ScreenshotCards = ({
    currentData,
    setSelectedScreenshot,
    detailed = false
}) => {
    return (
        <>
            {currentData?.map((item, index) => {
                return (
                    <Col lg="3" key={item?.screenshot_id}>
                        <Card className="mb-1">
                            <CardHeader
                                className={
                                    detailed
                                        ? 'justify-content-between pb-0 pt-75'
                                        : ''
                                }
                            >
                                <div className="text-truncate ">
                                    {item?.app?.location ? (
                                        <JpAvatar
                                            color={`light-info`}
                                            img={item?.app?.location}
                                            imgClassName="avatar-cover cursor-default"
                                        />
                                    ) : (
                                        <JpAvatar
                                            color={`light-info`}
                                            content={
                                                item.app_name &&
                                                `${item.app_name}`
                                            }
                                            initials
                                        />
                                    )}

                                    <span className="ms-50">
                                        {item.app_name}
                                    </span>
                                </div>
                            </CardHeader>
                            <CardBody className="pb-75">
                                <div className="text-center rounded bg-primary overflow-hidden my-50">
                                    <img
                                        loading='lazy'
                                        className="w-100 rounded img-fluid cursor-pointer dashboard_img bg-white"
                                        src={item.media_location}
                                        style={{
                                            height: 200
                                        }}
                                        onClick={() =>
                                            setSelectedScreenshot(
                                                item.screenshot_id
                                            )
                                        }
                                    />
                                </div>
                                <div className="d-flex align-items-center">
                                    {!detailed && (
                                        <JpAvatar
                                            icon={
                                                <FontAwesomeIcon
                                                    icon="person"
                                                    size="lg"
                                                />
                                            }
                                            color={types[item.app_type].color}
                                            className="position-absolute float-start mb-25"
                                        />
                                    )}
                                    {detailed && (
                                        <JpAvatar
                                            icon={
                                                <FontAwesomeIcon
                                                    icon={
                                                        productivityStatesIcons[
                                                            item.app_type
                                                        ].icon
                                                    }
                                                    size="lg"
                                                />
                                            }
                                            color={`light-${
                                                productivityStatesIcons[
                                                    item.app_type
                                                ].color
                                            }`}
                                        />
                                    )}

                                    <div className="text-truncate">
                                        {!detailed && (
                                            <p className="m-0">
                                                {parseName(
                                                    item.contractor_first_name,
                                                    item.contractor_last_name
                                                )}
                                            </p>
                                        )}
                                        <span className="text-truncate ms-50">
                                            {`${dateLocalizer.getIntlShortTime(
                                                item.timestamp
                                            )} - ${parseName(
                                                item.contractor_first_name,
                                                item.contractor_last_name
                                            )}`}
                                        </span>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                )
            })}
        </>
    )
}

export default ScreenshotCards
