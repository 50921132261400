// ** Dropdowns Imports
import CustomUserDropdown from './CustomUserDropdown'
import { JpAvatar } from '@Intelli/utilities'
import CustomNotificationDropdown from './CustomNotificationDropdown'
import SettingsButton from './SettingsButton'
import ValidatedAccAlert from './ValidatedAccAlert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UncontrolledTooltip } from 'reactstrap'

const CustomNavbarUser = () => {
    return (
        <ul className="nav navbar-nav align-items-center ms-auto">
            {/* <IntlDropdown /> */}
            {/* <CustomNotificationDropdown />
      <SettingsButton /> */}
            <ValidatedAccAlert />
            <CustomUserDropdown />
        </ul>
    )
}
export default CustomNavbarUser
