import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    project:{},
    isLoading: true
}

export const projectInfoRow = createSlice({
    name: 'projectInfoRow',
    initialState,
    reducers: {
        setProjectInfoRow: (state, action) => {
            return (state = action.payload)
        }
    }
})

export const { setProjectInfoRow } = projectInfoRow.actions

export default projectInfoRow.reducer
