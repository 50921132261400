// ** Imports
import React, { useState } from 'react'
import {
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    UncontrolledTooltip,
    Row,
    Col,
    CardHeader,
    CardBody,
    CardText,
    Card,
    CardTitle
} from 'reactstrap'

import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useMutation, useQuery } from '@tanstack/react-query'
import AdminAPI from '../../services/API'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    JpButton,
    SweetModal,
    JpLabel,
    SpinnerLoader,
    JpModal,
    JpAvatar
} from '@Intelli/utilities'
import JpTextAreaController from '@src/components/input/JpTextAreaController'
import JpSwitchController from '@src/components/input/JpSwitchController'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleInfo,
    faCircleQuestion
} from '@fortawesome/pro-solid-svg-icons'

const MySwal = withReactContent(Swal)

const SendInvite = ({
    modalStatus,
    modalAction,
    setShouldUpdate,
    shouldUpdate
}) => {
    const { t } = useTranslation()
    const API = new AdminAPI()

    const [cardOptions, setCardOptions] = useState({})

    const SignupSchema = yup.object().shape({
        message: yup.string().max(200, 'form.contractors.MESSAGE.max'),
        email: yup.string().email('EMAIL.invalid').required('EMAIL.required'),
        is_contract_process: yup
            .boolean()
            .oneOf([true, false], 'CONTRACTING_PROCESS.required')
    })

    const defaultValues = {
        email: '',
        message: '',
        is_contract_process: true
    }

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues,
        resolver: yupResolver(SignupSchema)
    })

    /* Send Invitation */
    const { mutate: invitationMutation } = useMutation(
        data => API.inviteFromContractors(data),
        {
            onSuccess: () => {
                //handleSuccess(t('modals.success'), t('modals.invitationSent'))
                SweetModal(
                    'success',
                    t('Success'),
                    t('modals.invitationSent'),
                    t('Ok')
                )
                if (setShouldUpdate) setShouldUpdate(!shouldUpdate)
            }
        }
    )

    const { data: clientServices, isLoading: clientServicesIsLoading } =
        useQuery(['clientServices'], () => API.clientServices(), {
            keepPreviousData: true,
            enabled: modalStatus,
            onSuccess: ({ services }) => {
                const cards = {}

                services.forEach(service => {
                    const servicesName = [
                        'WE_HIRE_FOR_YOU',
                        'YOU_HIRE'
                    ].includes(service.name)
                        ? 'is_contract_process'
                        : service.name

                    cards[servicesName] = true
                })

                setCardOptions(cards)
            }
        })

    const servicesIcons = {
        PAYROLL: {
            icon: 'fa-solid fa-hand-holding-dollar',
            color: 'green'
        },
        TIME: {
            icon: 'fa-solid fa-clock',
            color: 'info'
        },
        TIME_AND_PRODUCTIVITY: {
            icon: 'fa-solid fa-arrow-trend-up',
            color: 'cyan'
        },
        is_contract_process: { icon: 'fak fa-joobpay', color: 'primary' }
    }

    const services = clientServices?.services
        ?.sort((a, b) => a.order - b.order)
        .map(service => {
            const serviceName = ['WE_HIRE_FOR_YOU', 'YOU_HIRE'].includes(
                service.name
            )
                ? 'is_contract_process'
                : service.name
            return {
                id: service.id,
                name: serviceName,
                title: t(`views.services.${serviceName}.title`),
                subtitle: t(`views.services.${serviceName}.subtitle`),
                tooltip: t(`views.services.${serviceName}.subtitle`),
                icon: (
                    <JpAvatar
                        className="contract-method-icon border border-5"
                        size="xl"
                        color={servicesIcons[serviceName]?.color}
                        icon={
                            <FontAwesomeIcon
                                icon={servicesIcons[serviceName]?.icon}
                            />
                        }
                        imgClassName="dashboard_img"
                    />
                )
            }
        })

        

    const closeModal = () => {
        /* setOptionsSelect([]) */
        reset()
        modalAction(!modalStatus)
    }

    /* When the form has been completed */
    const onSubmit = data => {
        console.log(cardOptions)
        /* invitationMutation(data)
        closeModal() */
    }

    const modalities = [
        {
            name: 'contratar_joobpay',
            title: 'Contratar por Joobpay',
            txt: 'Gestione sus contratos atraves de Joobpay',
            tooltip: 'Gestione sus contratos atraves de Joobpay',
            icon: (
                <JpAvatar
                    className="contract-method-icon border border-5"
                    size="xl"
                    color={'primary'}
                    icon={<FontAwesomeIcon icon="fak fa-joobpay" />}
                    imgClassName="dashboard_img"
                />
            )
        },
        {
            name: 'contratar_por_joobpay',
            title: 'Contratar por Joobpay',
            txt: 'Gestione sus contratos atraves de Joobpay',
            tooltip: 'Gestione sus contratos atraves de Joobpay',
            icon: (
                <JpAvatar
                    className="contract-method-icon border border-5"
                    size="xl"
                    color={'primary'}
                    icon={<FontAwesomeIcon icon="fak fa-joobpay" />}
                    imgClassName="dashboard_img"
                />
            )
        },
        {
            name: 'tiempo_productividad',
            title: 'Tiempo y productividad',
            txt: 'Servicio de tiempo y productividad con monitoreo con ayuda de nuestra app de escritorio',
            tooltip:
                'Servicio de tiempo y productividad con monitoreo con ayuda de nuestra app de escritorio',
            icon: (
                <JpAvatar
                    className="contract-method-icon border border-5"
                    size="xl"
                    color={'primary'}
                    icon={
                        <FontAwesomeIcon
                            icon="fa-solid fa-arrow-trend-up"
                            className="text-white"
                        />
                    }
                    imgClassName="dashboard_img"
                />
            )
        },
        {
            name: 'pagar_por_joobpay',
            title: 'Pagar por Joobpay',
            tooltip:
                'Administre como sea necesario sus nominas, horas trabajadas, dias trabajados, entre otros',
            txt: 'Administre como sea necesario sus nominas, horas trabajadas, dias trabajados, entre otros',
            icon: (
                <JpAvatar
                    className="contract-method-icon border border-5"
                    size="xl"
                    color={'primary'}
                    icon={
                        <FontAwesomeIcon
                            icon="fa-solid fa-hand-holding-dollar"
                            className="text-white"
                        />
                    }
                    imgClassName="dashboard_img"
                />
            )
        }
    ]

    return (
        <div className="d-flex justify-content-end me-2">
            <JpButton
                id="add"
                type="add"
                tooltip
                tooltipTextCustom={t('views.activeContractors.table.invite')}
                options={{
                    onClick: () => closeModal(),
                    textLess: true,
                    iconPosition: 'center'
                }}
            />
            <JpModal
                isOpen={modalStatus}
                toggle={() => closeModal()}
                options={{
                    modalOptions: {
                        className: 'modal-lg',
                        centered: true,
                        tag: Card
                    }
                }}
                headerContent={
                    <h4>{t('views.activeContractors.table.sendInvite')}</h4>
                }
                bodyContent={
                    <div>
                        <div className="w-50">
                            <JpLabel labelOptions={{ for: 'email' }}>
                                Email*
                            </JpLabel>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        type="email"
                                        className=""
                                        placeholder="your@mail.com"
                                        invalid={errors.email && true}
                                        {...field}
                                    />
                                )}
                            />
                            <FormFeedback invalid>
                                {t(
                                    `form.contractors.${errors?.email?.message}`,
                                    {
                                        ns: 'errors'
                                    }
                                )}
                            </FormFeedback>
                        </div>

                        <Row className="mx-0 justify-content-between">
                            {!clientServicesIsLoading
                                ? services.map((item, index) => {
                                      const isActive = cardOptions[item.name]

                                      return (
                                          <Col
                                              md="5"
                                              key={index}
                                              tag={Card}
                                              color={
                                                  isActive
                                                      ? 'primary text-white'
                                                      : ''
                                              }
                                              style={{ width: '47%' }}
                                              className={`text-center mt-5 cursor-pointer mb-0 border shadow-none type__employer  ${
                                                  isActive ? 'active' : ''
                                              }`}
                                              onClick={() => {
                                                  setCardOptions({
                                                      ...cardOptions,
                                                      [item.name]:
                                                          !cardOptions[
                                                              item.name
                                                          ]
                                                  })

                                                  /* if (
                                                      optionsSelect.includes(
                                                          item.id
                                                      )
                                                  ) {
                                                      setOptionsSelect(
                                                          optionsSelect.filter(
                                                              i =>
                                                                  i !==
                                                                  item.id
                                                          )
                                                      )
                                                  } else {
                                                      setOptionsSelect([
                                                          ...optionsSelect,
                                                          item.id
                                                      ])
                                                  } */
                                              }}
                                          >
                                              {item.tooltip && (
                                                  <CardHeader className="justify-content-end align-items-center p-0 me-1 mt-1">
                                                      <UncontrolledTooltip
                                                          placement="top"
                                                          target={`help-${item.name}`}
                                                      >
                                                          {item.tooltip}
                                                      </UncontrolledTooltip>
                                                      <FontAwesomeIcon
                                                          id={`help-${item.name}`}
                                                          icon={
                                                              faCircleQuestion
                                                          }
                                                          className="text-yellow"
                                                          size="lg"
                                                      />
                                                  </CardHeader>
                                              )}
                                              <CardBody>
                                                  <div
                                                      style={{
                                                          marginTop: '-5rem'
                                                      }}
                                                  >
                                                      {item.icon}
                                                  </div>
                                                  <CardTitle className="my-1">
                                                      {item.title}
                                                  </CardTitle>
                                                  <CardText
                                                      className={`${
                                                          !item.subtitle
                                                              ? 'pb-2'
                                                              : ''
                                                      }`}
                                                  >
                                                      {item.subtitle}
                                                  </CardText>
                                              </CardBody>
                                          </Col>
                                      )
                                  })
                                : []}
                        </Row>

                        {/* <div className="mb-1">
                            <JpTextAreaController
                                name="message"
                                optionsInput={{
                                    placeholder: t('msgToContractorPlaceholder')
                                }}
                                control={control}
                                errors={errors}
                                title={t(
                                    'views.activeContractors.table.modal.msg'
                                )}
                            />
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100">
                            <JpSwitchController
                                name="is_contract_process"
                                control={control}
                                id="switch-info"
                                className="form-switch form-check-info mt-2"
                                label={t(
                                    'views.activeContractors.table.modal.contract_process'
                                )}
                            />
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                className="text-yellow mt-2"
                                size="lg"
                                id="contract-proccess-info"
                            />
                            <UncontrolledTooltip
                                placement="top"
                                target="contract-proccess-info"
                            >
                                {t(
                                    'views.activeContractors.table.modal.contract_process_info'
                                )}
                            </UncontrolledTooltip>
                        </div> */}
                        <div className="text-end mt-3">
                            <JpButton
                                type="send"
                                options={{
                                    onClick: handleSubmit(onSubmit)
                                }}
                            />
                        </div>
                    </div>
                }
            />
            {/* <Modal isOpen={modalStatus} toggle={() => closeModal()} centered>
                <ModalHeader toggle={() => closeModal()}>
                    {t('views.activeContractors.table.sendInvite')}
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <JpLabel labelOptions={{ for: 'email' }}>
                            Email*
                        </JpLabel>
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    type="email"
                                    className="mb-1"
                                    placeholder="your@mail.com"
                                    invalid={errors.email && true}
                                    {...field}
                                />
                            )}
                        />
                        <FormFeedback invalid>
                            {t(`form.contractors.${errors?.email?.message}`, {
                                ns: 'errors'
                            })}
                        </FormFeedback>
                        <div className="mb-1">
                            <JpTextAreaController
                                name="message"
                                optionsInput={{
                                    placeholder: t('msgToContractorPlaceholder')
                                }}
                                control={control}
                                errors={errors}
                                title={t(
                                    'views.activeContractors.table.modal.msg'
                                )}
                            />
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100">
                            <JpSwitchController
                                name="is_contract_process"
                                control={control}
                                id="switch-info"
                                className="form-switch form-check-info mt-2"
                                label={t(
                                    'views.activeContractors.table.modal.contract_process'
                                )}
                            />
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                className="text-yellow mt-2"
                                size="lg"
                                id="contract-proccess-info"
                            />
                            <UncontrolledTooltip
                                placement="top"
                                target="contract-proccess-info"
                            >
                                {t(
                                    'views.activeContractors.table.modal.contract_process_info'
                                )}
                            </UncontrolledTooltip>
                        </div>
                        <div className="text-end mt-3">
                            <JpButton type="send" buttonType="submit" />
                        </div>
                    </Form>
                </ModalBody>
            </Modal> */}
        </div>
    )
}

export default SendInvite
